import React, { Component } from 'react';
import { GoogleLogin, googleLogout} from '@react-oauth/google';
import Config from "../data/config.json"; 

import '../css/bootstrap.min.css';
import { Alert} from 'react-bootstrap';


class LoginGate extends Component {
    constructor() {
        super();
        this.success = this.success.bind(this);
        this.killToken = this.killToken.bind(this);
        this.fail = this.fail.bind(this);
        this.state = { error: "", pending:false };
    }
    //Revokes the Google Token if it is found that it doesn't grant access to the server
    killToken(accessToken) {
        fetch(`https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`,
            {
                contentType: "application/json",
                dataType: 'jsonp'
            }
        ).catch(error => {
            this.setState({
                "error": "Logging in failed. Unexpected error " + error
            });
        });
    }
    success(response) {
        this.setState({ pending: true });
        let token = response.credential;
        for (let k in response) {
            console.log('*** ' + k+': ' + response[k]); 
        }
        fetch('/api/auth/login', {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type' : 'application/json'
            }),
            body: JSON.stringify({accessToken: response.accessToken})
        }).then(response => response.json()).then(data => {
            if (data.success) {
                //We're in. 
                this.props.onAuth(data);
            } else {
                //We got booted. Find out why and display an error.
                let message = "";
                let ERRORS = Config.auth.AUTH_ERRORS;
                switch (data.error) {
                    case ERRORS.AUTHORIZATION_FAILED: {
                        message = "Authorization failed";
                        break;
                    } 
                    case ERRORS.INVALID_EMAIL_DOMAIN: {
                        message = "Invalid domain. Please sign in using a raudas.is account.";
                        break;
                    }
                    default: {
                        message = "Unexpected error. Try again later: " + JSON.stringify(data);
                        break;
                    }
                }
                this.setState({ pending:false, error: message });
                this.killToken(response.accessToken);
            }
        }); 
    }
    fail(response) {
        //Todo: more detailed error handling. 
    }
    render() {
        let alert;
        if (this.state.error) {
            alert = <Alert variant="danger">
                    Error: {this.state.error}
                </Alert>;
        } else {
            if (this.state.pending) {
                alert =
                    <Alert variant="warning">
                        Logging in. Please wait.
                </Alert>;
            } else {
                alert =
                    <Alert variant="primary">
                        Access to this website is restricted to Rauðás employees. Please log in to your Rauðás Google account.
                </Alert>;
            }
        }

        return <div className="centeredChilds">
            { alert }
            <div style={{width:"250px", margin:"0 auto"}}>
            <GoogleLogin
            clientId={Config.auth.GCID}
            text="Log In"
            theme='filled_blue'
            scope="profile email https://www.googleapis.com/auth/adsense.readonly https://www.googleapis.com/auth/admob.readonly"
            responseType="id_token token"
            onSuccess = { this.success }
            onError = { this.fail }
            auto_select
            useOneTap
            hosted_domain="raudas.is"
            context="Login"
        /></div></div>;
    }
}

class LogoutGate extends Component {
    constructor() {
        super();
        this.success = this.success.bind(this);
    }
    success(event) {
        event.preventDefault();
        console.log("Logged out! ");
        fetch('/api/auth/logout', {
            method: 'post'
        }).then(response => response.json()).then(data => {
            if (data.success) {
                googleLogout();
                window.location = "/";
            }
        });
    }
    
    render() {
        return (<a href='/#' onClick={this.success}>Log Out</a>)
    }
}

export { LoginGate, LogoutGate };
import moment from 'moment';

class AgeService{
    static getAge(ageString) {
        let date = moment(ageString);
        let today = moment(Date.now());

        var years = today.diff(date, 'year');
        date.add(years, 'years');
        var months = today.diff(date, 'months');
        date.add(months, 'months');
        var days = today.diff(date, 'days');
        date.add(days, 'days');
        var hours = today.diff(date, 'hours');
        date.add(hours, 'hours');
        var minutes = today.diff(date, 'minutes');
        date.add(minutes, 'minutes');
        var seconds = today.diff(date, 'seconds');

        let diff = { years, months, days, hours, minutes, seconds };
        return diff;        
    }

    static getTimeDifference(ageOne, ageTwo) {
        if (!ageOne) {
            ageOne = new Date().toISOString();
        }
        if (!ageTwo) {
            ageOne = new Date().toISOString();
        }
        let a = moment(ageOne);
        let b = moment(ageTwo);

        var years = a.diff(b, 'year');
        b.add(years, 'years');
        var months = a.diff(b, 'months');
        b.add(months, 'months');
        var days = a.diff(b, 'days');

        let diff = { years, months, days };
        return diff;
    }

    static getAgeString(age, full = false, shortHand = false) {
        let single, plural;
        if (shortHand) {
            let short = {
                'y': 'y',
                'M': 'm',
                'd': 'd',
                'h': 'h',
                'm': 'm',
                's': 's'
            };
            single = short;
            plural = short;
        } else {
            single = {
                'y': ' year',
                'M': ' month',
                'd': ' day',
                'h': ' hour',
                'm': ' minute',
                's': ' second'
            };

             plural = {
                'y': ' years',
                'M': ' months',
                'd': ' days',
                'h': ' hours',
                'm': ' minutes',
                's': ' seconds'
            };
        }

        let ageString = "";
        ageString += (age.years === 1) ? age.years + single.y + ", " : (age.years >= 2) ? age.years + plural.y+", " : "";
        ageString += (age.months === 1) ? age.months + single.M + ", " : (age.months >= 2) ? age.months + plural.M + ", " : "";
        ageString += (age.days === 1) ? age.days + single.d: age.days + plural.d;
        if (full) {
            ageString += ", ";
            ageString += (age.hours === 1) ? age.hours + single.h : (age.hours >= 2) ? age.hours + plural.h + ", " : "";
            ageString += (age.minutes === 1) ? age.minutes + single.m + ", " : (age.minutes >= 2) ? age.minutes + plural.m + ", " : "";
            ageString += (age.seconds === 1) ? age.seconds + single.s : age.seconds + plural.s; 
        }
        return ageString;
    }
    
}

function cake(name, value, expiresInDays, path) {
    if (typeof value !== 'undefined') { // name and value given, set the cookie

        let c = name + '=' + encodeURIComponent(value);
        if (expiresInDays) {
            var date = new Date();
            date.setTime(date.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
            c += '; expires=' + date.toUTCString();
        }
        if (path) {
            c += '; path=' + path;
        }

        //2019-09-09: Firefox v69 has been causing issues for the last few days. After logging it turns
        //out that some people had old cookies from the '/' path interfering with their game cookies.
        //As in, they had a 'results=; path=/' cookie, then they added a 'results=1,2,3; path=/hearts' cookie
        //and then when you access document.cookie you get both, e.g. 'results=; results=1,2,3' and then it's just
        //random whether the first result is the old one or the new one. So, for Firefox we now make sure to delete
        //the cookie explicitly for the root and the path before setting a new one!
        deleteCake(name);
        //... and apparently this is still not enough! ARGGGGHHH. See fix below in cookie parsing code...

        document.cookie = c;

        try {
            //See comment above. Just tracking this for a while longer, see if we're still having any problems!
            let returned = cake(name);
            if (returned !== value) {
                if (!value && !returned) {
                    return; //Set '' and got null back, fine
                }
                if (!navigator.cookieEnabled) {
                    //User turned off cookies, fine...
                    return;
                }
            }
        } catch (ex) { console.log(ex); }
    } else { // just the name, find the cookie
        var cookies = document.cookie.split(';');
        let cookieDict = {}; //Why are we going through all the cookies instead of just returning when we find the right one?
        //Because of a stupid browser called Firefox 69 which has cookie issues and now we have duplicate cookies for some users,
        //things like "results=123; id=foo; results=456"; And then the old way would return the first results, but actually the
        //later results is the last one to be set, so that's actually the one we want. So, we parse everything and add it to an object
        //making sure that the last cookie of each name in the string is the one used, hopefully that will make it alright for %$&$%/$%& Firefox!
        for (var i = 0; i < cookies.length; i++) {
            let c = cookies[i].replace(/^\s*|\s*$/g, '');
            let parts = c.split('=');
            cookieDict[parts[0]] = decodeURIComponent(parts[1]);
        }
        return cookieDict[name] || null;

    }
}

function deleteCake(name){
    var deletedCookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = deletedCookie;
    document.cookie = deletedCookie + ' path=/';

    //Lets also try to delete it from window.name for those without cookies...
    try {
        var cookieData = JSON.parse(window.name);
        if (cookieData && typeof cookieData == 'object' && typeof cookieData[name] !== 'undefined'){
            delete cookieData[name];
            window.name = JSON.stringify(cookieData);
        }
    } catch (ex) {
        //Do nothing, just a best effort try...
    }
}

export { AgeService, cake, deleteCake };

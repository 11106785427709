import React, { useEffect, useState } from "react";
import { DataTable } from "../components";


/*
    [
        {
            month, year, visitors, average_daily_visitors
        }
    ]
*/
const MonthTrafficTable = ({data,subHeader}) => {
    return <DataTable title="Visitor Month Summary" subHeader={subHeader} items={data} keys={["year","month","visitors","min","average_visitors","max"]} />
}

export const MonthTrafficAnalyzer = () => {
    const [month, setMonth] = useState((new Date().getMonth() + 11) % 12);
    const [data, setData] = useState(undefined);
    const months = ["Januar","Febuary","March","April","May", "June", "July", "August", "September", "October", "November", "December"];

    const formatData = (data) => {
        // number format, average, min, max
        data.forEach(d => {
            d.average_visitors = Intl.NumberFormat('de-DE').format(d.average_visitors);
            d.min = Intl.NumberFormat('de-DE').format(d.min);
            d.max = Intl.NumberFormat('de-DE').format(d.max);
            d.visitors = Intl.NumberFormat('de-DE').format(d.visitors);
        })
        data.sort((a,b) => a.year - b.year)

        return data;
    }

    useEffect(() => {
        fetch(`/api/visitors/summary/${parseInt(month) + 1}`).then(resp => {
            if(resp.status === 200){
                resp.json().then(data => {
                    setData(formatData(data));
                })
            }else{
                setData(undefined);
            }
        })
    }, [month])

    const subHeader = (
        <div className="center-align overview-subheader">
        <b className="table-hidden">Month analyzer</b>
        <i className="table-hidden">Select Month</i>
        <select onChange={e => setMonth(e.target.value)} value={month}>
            {months.map((m,i) => <option key={i} value={i}>{m}</option>)}
        </select>
        </div>
    )

    return <div>
        {data ? undefined : subHeader}
        <div className="fullsize-table">
        {data ? <MonthTrafficTable subHeader={subHeader} data={data}/> : "loading"}
        </div>
    </div>
}
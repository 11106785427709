import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './js/serviceWorker';
import { AdminApp } from './js/AdminApp';
import { Revenue, BadWords, Games, SolitaireStats, MultiplayerStats, Traffic } from './js/pages';
import  WinRates  from './js/WinRates/WinRates';
import  Phrases  from './js/Phrases/Phrases';
import { AdFree } from './js/AdFree/AdFree';
import { Freestar } from './js/freestar/freestar';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {ErrorPage} from './js/Pages/ErrorPage';
import Events from './js/Pages/EventPage';
import Servers from './js/Pages/ServerPage';
import Config from "./data/config.json"; 
const links = [
    { link: "/games", name: "Games", component: Games },
    { link: "/freestar", name: "Freestar", component: Freestar },
    { link: "/revenue", name: "Revenue", component: Revenue },
    { link: "/solitaire", name: "Solitaire Stats", component: SolitaireStats },
    { link: "/multiplayer", name: "Multiplayer", component: MultiplayerStats },
    { link: "/nonolist", name: "Bad Words", component: BadWords },
    { link: "/servers", name: "Servers", component: Servers },
    { link: "/traffic", name: "Traffic", component: Traffic },
    { link: "/winrates", name: "Win Rates", component: WinRates },
    { link: "/phrases", name: "Phrases", component: Phrases },
    { link: "/adFree", name: "Ad Free Codes", component: AdFree },
    { link: "/events", name: "Events", component: Events },
    { link: "/errors", name: "Errors", component: ErrorPage },
    {link: "/", name: "", component: Games },
];

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(<GoogleOAuthProvider clientId={Config.auth.GCID}><AdminApp links={links} /></GoogleOAuthProvider>);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

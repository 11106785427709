/*
    pages.js
    stores all the top level pages, but none of their components.
    The inention is that the page-components are as simple as possible: essentially limited to communicating with the API and setting the base form of the page.
    Data manipulation or specific functionality of the page is better delegated to the imported components. 
*/
import React, { Component } from 'react';
import '../css/simpleadmin.css';
import '../css/bootstrap.min.css';
import {MultiplayerStatTable, RevenueTable, TopRevenueTable, GameOverviewTable, GameCategoryTable, SolitaireTable, BadWordTable, BadWordForm, CurrencySelector, DataTableContainer, ServerTable, TrafficTable } from './components';
import Config from "../data/config.json";
import { Alert } from 'react-bootstrap';
import { MonthTrafficAnalyzer, OverviewTrafficAnalyzer,VisitorPerDayChart } from './Traffic';

class Revenue extends Component {
    constructor(props) {
        super(props);
        this.lastDbUpdate = null;
        this.state = {
            data: [],
            topdays: [],
            topmonths: [],
            //Fallback, if the currency data doesn't arrive we still have the euro
            currencies: { "EUR": 1 },
            selectedCurrency: "EUR"
        };
    }
    refreshData() {
        
        if (!this.lastDbUpdate) {
            fetch("/api/revenue/update")
            .then(() => {
                this.lastDbUpdate = new Date();
                this.refreshData();
            });
        }

        fetch("/api/revenue")
            .then(response => response.json())
            .then(data => {
                this.setState({ data: data });
            });
        fetch("/api/revenue/topdays")
            .then(response => response.json())
            .then(data => {
                this.setState({ topdays: data });
            });
        fetch("/api/revenue/topmonths")
            .then(response => response.json())
            .then(data => {
                this.setState({ topmonths: data });
            });
        fetch("https://apis.is/currency/")
            .then(response => response.json())
            .then(data => {
                let currencies = {};
                let euro = data.results.filter(c => c.shortName === "EUR")[0].bidValue;
                for (let curr of data.results) {
                    //I have no idea what values were supposed to be invoked and when: Value, Bid, or Ask. 
                    //I think I copied the application method correctly. 
                    //Assume slight rounding errors when converting from the Euro. 
                    currencies[curr.shortName] = euro/curr.bidValue;
                }
                currencies.ISK = euro;
                this.setState({ currencies });
            });
    }
    
    componentDidMount() {
        this.refreshData();
    }
    render() {
        //Add an array to the Config.json at auth.ADMIN_EMAILS with emails that may see revenue. 
        let admins = Config.auth.ADMIN_ACCOUNTS;
        if (this.props.user.email && admins.includes(this.props.user.email)) {
            let urelt = <Alert variant="warning">Fetching data</Alert>;
            if (this.state.data.length > 0) {
                let today = new Date();
                let latestDatabaseDate = new Date(this.state.data[0].date);
                if (today.getDate () === latestDatabaseDate.getDate() && today.getFullYear() === latestDatabaseDate.getFullYear() && today.getMonth() === latestDatabaseDate.getMonth()) {
                    urelt = null;
                } else {
                    urelt = <Alert variant="danger">Database out of date! Most recent revenue data was at <b>{latestDatabaseDate.toLocaleDateString()}</b></Alert>;
                }
            }

            return <div>
                {urelt}
                <CurrencySelector onSelect={(curr) => this.setState({ selectedCurrency: curr })} selected={this.state.selectedCurrency} currencies={["DKK", "USD", "EUR", "GBP", "ISK"]} />
                <RevenueTable data={this.state.data} currency={this.state.selectedCurrency} ratio={this.state.currencies[this.state.selectedCurrency]} />
                <DataTableContainer size="md">
                    <TopRevenueTable data={this.state.topdays} title="Top earning days" currency={this.state.selectedCurrency} ratio={this.state.currencies[this.state.selectedCurrency]} />
                    <TopRevenueTable data={this.state.topmonths} title="Top earning months" currency={this.state.selectedCurrency} ratio={this.state.currencies[this.state.selectedCurrency]} />
                </DataTableContainer>
            </div>;
        } else {
            return <Alert variant="danger">You do not have permission to view this page.</Alert>;
        }
    }
}

class Games extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.newGame = this.newGame.bind(this);
    }

    newGame(game) {
        fetch("/api/games", {
            method: "POST",
            body: JSON.stringify(game),
            headers: {
                'content-type': 'application/json',
            },
        }).then(response => response.json()).then(data => {
            this.refreshData();
        }); 
    }

    refreshData() {
        fetch("/api/games")
        .then(response => response.json())
            .then(data => {
                for (let d of data) {
                    d.year = new Date(d.release_date).getFullYear();
                }
                this.setState({ data: data });
        });
    }

    componentDidMount() {
        this.refreshData();
    }

    render() {
        let data = this.state.data !== null ? this.state.data : [];
        return (<div>
            <GameOverviewTable onPost={this.newGame} data={data} />
            <GameCategoryTable data={data} sortKey="year" category="Year" />
            <GameCategoryTable data={data} sortKey="type" category="Type" />
        </div>);
    }
}

class SolitaireStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    refreshData() {
        fetch("/api/solitaire-stats")
            .then(response => response.json())
            .then(data => {
                this.setState({ data: data });
            });
    }
    componentDidMount() {
        this.refreshData();
    }
    render() {
        let data = this.state.data !== null ? this.state.data : [];
        return (<SolitaireTable data={data}/>);
    }
}

class BadWords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.postBadWord = this.postBadWord.bind(this);
        this.deleteBadWord = this.deleteBadWord.bind(this);
    }
    refreshData() {
        fetch("/api/bad-words")
            .then(response => response.json())
            .then(data => {
                this.setState({ data: data });
            });
    }
    deleteData(word) {
        fetch(`/api/bad-words/${word}`, {
            method: 'DELETE',
        }).then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.refreshData();
    }
    deleteBadWord(word) {
        let data = this.state.data;
        for (let i = 0; i < data.length; i++) {
            let ent = data[i];
            if (ent.word === word) {
                data.splice(i, 1);
                this.deleteData(word);
                this.setState({ data });
                break;
            }
        }
    }
    postBadWord(word) {
        fetch("/api/bad-words/"+word, {
            method: "PUT"
        }).then(response => response.json()).then(data => {
            let dataList = this.state.data;
            dataList.push(data);
            this.setState({ dataList });
        }); 
    }
    render() {
        return (<div>
            <BadWordTable data={this.state.data} onDelete={this.deleteBadWord} />
            <BadWordForm onPost={this.postBadWord} />
        </div>);
    }
}

class MultiplayerStats extends Component {
    constructor(props) {
        super(props);
        let d = new Date();
        this.state = {
            pending:false,
            data: {},
            date: d
        };
        this.refreshData = this.refreshData.bind(this);
    }
    refreshData() {
        this.setState({ pending: true });
        let d = this.state.date;
        let date = d.getFullYear() + "-" + (0 + (1 + d.getMonth()).toString()).slice(-2) + "-" + (0 + (d.getDate()).toString()).slice(-2);
        fetch(`/api/multiplayer-stats/all/report/${date}`)
            .then(response => response.json())
            .then(data => {
                this.setState({ pending:false, data: data });
            });
    }
    componentDidMount() {
        this.refreshData();
    }
    render() {
        let data = this.state.data !== null ? this.state.data : [];
        return (
            <MultiplayerStatTable pending={this.state.pending} data={data} onFetch={() => this.refreshData()} date={this.state.date} onDateChange={(e) => this.setState({ date: e })} />
        );
    }
}

class Servers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pending: false,
            data: {},
        };
        this.refreshData = this.refreshData.bind(this);
    }
    refreshData() {
        this.setState({ pending: true });
        fetch(`/api/servers`)
            .then(response => response.json())
            .then(data => {
                this.setState({ pending: false, data: data });
            });
    }
    componentDidMount() {
        this.refreshData();
    }
    render() {
        let pendingAlert = this.state.pending ? <Alert variant="warning">Fetching data. Please wait, this can take several seconds as we poll all servers</Alert> : null;
        let data = this.state.data || {};
        return (
            <div>
                {pendingAlert}
                <ServerTable pending={this.state.pending} onFetch={() => this.refreshData()} data={data} />
            </div>
        );
    }
}

class Traffic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pending: false,
            today: null,
            yesterday: null,
            yesterdaySameDayLastWeek: null,
            sameDayLastMonth: null,
            lastMonth: null,
            thisMonth: null,
            monthBeforeLast: null,
            visitorsPerDay: [],
            earlyDate : new Date(),

        };
        this.refreshData = this.refreshData.bind(this);
    }
    refreshData() {
        this.setState({ pending: true });
        let d = new Date();
        let thisYear = parseInt(d.getFullYear());

        fetch(`/api/visitors/today`)
            .then(response => response.json())
            .then(data => {
                this.setState({ pending: false, today: data });
            });
        fetch(`/api/visitors/day`)
            .then(response => response.json())
            .then(data => {
                this.setState({ pending: false, yesterday: data.yesterday, yesterdaySameDayLastWeek: data.sameDayLastWeek });
            });
        fetch(`/api/visitors`)
            .then(response => response.json())
            .then(data => {
                this.setState({ pending: false, thisMonth: data.thisMonth });
            });
        fetch(`/api/visitors/perday`)
            .then(response => response.json())
            .then(data => {
                this.setState({ pending: false, perday: data });
            });    
        fetch(`/api/visitors/month/${thisYear}`)
            .then(response => response.json())
            .then(data => {
                this.setState({ pending: false, lastMonth: data.lastMonth, monthBeforeLast: data.monthBeforeLast });
            });
    }
    componentDidMount() {
        this.refreshData();
    }
    render() {
        let pendingAlert = this.state.pending ? <Alert variant="warning">Fetching data. Please wait while we prepare the data.</Alert> : null;
        let data = this.state || {};
        const years =[];
        for(var i = 2019; i <= new Date().getFullYear(); i++){
            years.push(i);
        }
        return (
            <div>
                {pendingAlert}
                <TrafficTable data={data} />
                <MonthTrafficAnalyzer/>
                <VisitorPerDayChart data={data.perday ?? []}></VisitorPerDayChart>
                <OverviewTrafficAnalyzer/>
            </div>
        );
    }
}


export {Revenue, BadWords, Games, SolitaireStats, MultiplayerStats, Servers, Traffic };

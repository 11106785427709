import React, { Component } from 'react';
import '../../css/simpleadmin.css';
import '../../css/bootstrap.min.css';
import {DatePick} from '../components';
import WinRateTables from './WinRateTables';
import { Alert } from 'react-bootstrap';
let urlDate = (date) => date.toISOString().split("T")[0];

class WinRates extends Component {
    constructor(props) {
        super(props);
        let d = new Date();
        this.state = {
            pending: false,
            gameList: false,
            game:0,
            cache: {},
            date: d
        };
        this.refreshData = this.fetchData.bind(this);
        this.fetchInitData = this.fetchInitData.bind(this);
        this.processData = this.processData.bind(this);
        this.convertData = this.convertData.bind(this);
        this.updateCache = this.updateCache.bind(this);
        this.voidCache = this.voidCache.bind(this);
    }

    fetchData(date) {
        let d = urlDate(date);
        this.setState({pending:true});
        if(!(d in this.state.cache)){        
            this.setState({ date, pending: true });
            fetch("/api/win-stats/all/"+d).then(data => data.json()).then(data => {
                this.processData(data, d);
            });
        } else{
            this.setState({date});
        }
    }

    fetchInitData(){        
        fetch("/api/games").then(response => response.json()).then(data => {
                for (let d of data) {
                    d.year = new Date(d.release_date).getFullYear();
                }
                this.setState({ gameList: data });
                this.fetchData(new Date());
        });
    }

    updateCache(date,data){
        let c = this.state.cache;
        c[date] = data;
        this.setState({
            'cache':c,
            'pending':false,
        });
    }

    convertData(data){
        let gameData = {};
        for(let game in data){
            let gameType = this.state.gameList[game];
            if(gameType === 'solitaire') continue; //We do not want solitaires. 
            let gameTotal = data[game].total;
            let rows = [];
            for(let player in data[game]){
                if(player === "total") continue;
                let p = data[game][player];
                let total = p.total;
                let ratio = ((100*total)/gameTotal).toFixed(2);
                rows.push([player, ratio, total]);
            }
            gameData[game] = rows;
        }
        return gameData;
    }

    processData(data,date){
        let Wins = this.convertData(data['Wins']);
        let TWins = this.convertData(data['WinTournament']);
        this.updateCache(date, {'Wins':Wins,'TournamentWins':TWins});
    }
    componentDidMount() {
        this.fetchInitData();
    }

    getGameData(gameList, game,date){
        date = urlDate(date);
        let data =this.state.cache[date] || {};
        game = game < gameList.length ? gameList[this.state.game] : "";
        let win = null;
        let twin = null;
        if('Wins' in data){
            win = data['Wins'][game.name] || null;
            twin = data['TournamentWins'][game.name] || null;
        } 
        return{win,twin};
    }

    voidCache(date){
        date = urlDate(date);
        let c = this.state.cache;
        c[date] = null;
        delete c[date];
        this.setState({
            cache:c
        });
    }

    render() {        
        let pendingAlert = this.state.pending ? <Alert variant="warning">Fetching data. Please wait a bit.</Alert> : null;
        let gameList = this.state.gameList ? this.state.gameList.filter(x => x.type !== "solitaire") : [];
        let gameData = this.getGameData(gameList, this.state.game, this.state.date);

        return (
            <div>
                {pendingAlert}
                <DatePick onFetch={e => {this.voidCache(this.state.date); this.fetchData(this.state.date);}} onDateChange={e => this.fetchData(e)} date={this.state.date}></DatePick>
                <WinRateTables pending = {this.state.pending} gameList={gameList} selectedGame={this.state.game} winData = {gameData.win} tournamentWinData = {gameData.twin} onNewGame={e => this.setState({game:e})}></WinRateTables>
            </div>
        );
    }
}

export default WinRates;
import React from "react";
import { DataTable } from "../components";
import { secondsToTimeString } from "./SumTable";

const Comparison = ({now, old, inverted = false}) => {
    now = parseFloat(now);
    old = parseFloat(old);
    const p = (100 * ((now - old) / old)).toFixed(2);
    return <i style={{"color": (inverted ? old < now : old > now) ? "red" : "green"}}>
        ({p}%)
    </i>
}

const Table = ({title, data, oldData}) => {
    const items = data.map(game => {
        const item = {};
        let oldItem = oldData.find(g => g.dimensionValues[0].value === game.dimensionValues[0].value ) 
        if(!oldItem){
            oldItem = game;
        }
        item["Game"] = <>
            {game.dimensionValues[0].value}
        </>;
        item["Active Users"] = <>
            {game.metricValues[0].value} 
            <Comparison now={game.metricValues[0].value} old={oldItem.metricValues[0].value}/>
        </>;
        item["Sessions"] = <>
            {game.metricValues[4].value}
            <Comparison now={game.metricValues[4].value} old={oldItem.metricValues[4].value}/>
        </>;
        item["Avg. Session Length"] = <>
            {secondsToTimeString(game.metricValues[1].value,  true)}
            <Comparison now={game.metricValues[1].value} old={oldItem.metricValues[1].value}/>
        </>;
        item["Time Spent"] =<>
            {secondsToTimeString(parseInt(game.metricValues[1].value) * parseInt(game.metricValues[4].value), true)}
            <Comparison now={parseInt(game.metricValues[1].value) * parseInt(game.metricValues[4].value)} old={parseInt(oldItem.metricValues[1].value) * parseInt(oldItem.metricValues[4].value)}/>
        </> ;
        item["Bounce Rate"] = <>
            {(100 * parseFloat(game.metricValues[2].value)).toFixed(2)}%
            <Comparison now={game.metricValues[2].value} old={oldItem.metricValues[2].value} inverted={true}/>
        </>;       
        item["New Users"] =<>
            {game.metricValues[3].value}
            <Comparison now={game.metricValues[3].value} old={oldItem.metricValues[3].value}/>
        </>;
        return item;

    })
    const keys = ["Game", "Active Users", "Sessions" , "Avg. Session Length", "Time Spent", "Bounce Rate", "New Users"];
    return <DataTable title={title} items={items} keys={keys}/>
}
export const CompTable = ({data, oldData}) => {
    return <div>
        <Table title="Freestar games" data={data.freestar} oldData={oldData.freestar}></Table>
        <Table title="Google games" data={data.google} oldData={oldData.google}></Table>
    </div>
}
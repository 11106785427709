import React, { Component } from 'react';
import '../../css/simpleadmin.css';
import '../../css/bootstrap.min.css';
import PhraseTable from './PhraseTable';
import { Alert } from 'react-bootstrap';
import {arrayMoveImmutable} from 'array-move';



class Phrases extends Component{
    constructor(props){
        super();
        this.state={
            data:null,
            deleted : [],
        }
        this.fetchPhrases = this.fetchPhrases.bind(this);
        this.reorder = this.reorder.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.newItem = this.newItem.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.saveTable = this.saveTable.bind(this);
    }
    componentDidMount(){
        this.fetchPhrases();
    }

    saveTable(type){
        let unchanged = (e,i) => e.id === e.oid && e.text === e.otext && e.type === e.otype && e.nr === i;
        let del, patch, post;
        del = this.state.deleted[type].filter(x => x.oid !== null);
        patch = this.state.data[type].filter((x,i) => x.oid !== null && !unchanged(x,i + 1));
        patch.forEach((e,i) => {
            e.nr = i + 1;
        });
        post = this.state.data[type].filter(x => x.oid == null);
        let b = {
            delete : del,
            patch,
            post 
        };
        fetch("/api/phrases", {
            method:"PATCH",            
            body: JSON.stringify(b),
            headers: {
                'content-type': 'application/json',
            },
        }).then(data => data.json()).then(data => {
            this.setState({
                deleted: [],
                data:this.state.data,
            })
        });
    }

    fetchPhrases(){
        fetch("/api/phrases").then(data => data.json()).then(data => {
            let d = {'text':[],'emoji':[]};
            let deleted = {'text':[],'emoji':[]};
            d.text = data.filter(x => x.type==="text").sort((a,b) => a.nr - b.nr);
            d.emoji = data.filter(x => x.type==="emoji").sort((a,b) => a.nr - b.nr);
            for(let elem of d.text){
                elem.oid = elem.id;
                elem.otext = elem.text;
                elem.otype = elem.type;
            }
            for(let elem of d.emoji){
                elem.oid = elem.id;
                elem.otext = elem.text;
                elem.otype = elem.type;
            }
            this.setState({data:d, deleted});
        });
    }
    reorder(e, type){
        let data = this.state.data;
        let list = data[type];
        const {active, over} = e;
        
        if (active.id !== over.id) {
            const oldIndex = active.id - 1;
            const newIndex = over.id -1;
            list = arrayMoveImmutable(list, oldIndex, newIndex);
            data[type] = list;
            this.setState({data});
        }
    }

    removeItem(type, id){
        let data = this.state.data;
        let deleted = this.state.deleted;
        let list = data[type];
        let elementIndex = list.findIndex(x => x.id === id);
        if(elementIndex !== -1){
            deleted[type].push(list[elementIndex]);
            list = list.splice(elementIndex, 1);
            this.setState({data, deleted});
        }
    }

    newItem(type){
        let data = this.state.data;
        data[type].push({
            id: "",
            oid : null,
            text: "",
            type,
            nr: 1+data[type].length,
        })
        this.setState({data});
    }

    onFieldChange(type, phraseID, field, value){
        let elem = this.state.data[type][phraseID - 1];
        if(elem){
            elem[field] = value;
            this.setState({data:this.state.data});
        }
    }

    render(){
        if(!this.state.data){
            return  <Alert variant="warning">Fetching data. Please wait a bit.</Alert>;
        }
        return <div>
            <PhraseTable onSave = {e => this.saveTable("text")} onChange={(nr, field, value) => this.onFieldChange("text", nr, field, value)} onDelete={(id) => this.removeItem("text", id)} onNew={e => this.newItem("text")}  title="Text Phrases" onSortEnd={e => this.reorder(e, "text")} items={this.state.data.text}></PhraseTable>
            <PhraseTable onSave = {e => this.saveTable("emoji")}  onChange={(nr, field, value) => this.onFieldChange("emoji", nr, field, value)} onDelete={(id) => this.removeItem("emoji", id)} onNew={e => this.newItem("emoji")}  onSortEnd={e => this.reorder(e, "emoji")} items={this.state.data.emoji}></PhraseTable>
        </div>
    }
}

export default Phrases;
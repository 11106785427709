import { LineChart } from '@mui/x-charts/LineChart';
import React, { useState } from "react";

function groupDataByMonth(data) {
    const groupedData = {};

    data.forEach((d) => {
        const date = new Date(d.date);
        const monthYear = date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' });
        const day = date.toLocaleDateString('en-US', { day: '2-digit' });

        if (!groupedData[monthYear]) {
            groupedData[monthYear] = [];
        }

        groupedData[monthYear].push({ day, visitors: d.visitors });
    });

    return groupedData;
}

export function VisitorPerDayChart({ data }) {
    const groupedData = groupDataByMonth(data);
    const [selectedMonths, setSelectedMonths] = useState([]);

    const minValue = Math.min(...data.map(d => d.visitors));
    const maxValue = Math.max(...data.map(d => d.visitors));

    const xAxisData = Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0'));

    const handleMonthSelection = (event) => {
        const { value, checked } = event.target;
        setSelectedMonths((prevSelected) =>
            checked ? [...prevSelected, value] : prevSelected.filter((month) => month !== value)
        );
    };

    const selectAllMonths = (months) => {
        setSelectedMonths(months);
    }

    const uniqueMonths = Object.keys(groupedData);

    const groupedByYear = uniqueMonths.reduce((acc, monthYear) => {
        const [year] = monthYear.split('/');
        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(monthYear);
        return acc;
    }, {});

    const months = {'01':'Jan', '02':"Feb", '03':"Mar", '04':"Apr", '05':"May", '06':"Jun", '07':"Jul", '08':"Aug", '09':"Sep", '10':"Oct", '11':"Nov", '12':"Dec"}

    // on load, select the current month and the current month one year ago
    if (selectedMonths.length === 0 && Object.keys(groupedByYear).length > 0) {
        //const currentMonthYear = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' });
        selectAllMonths(groupedByYear[(new Date().getMonth()+1).toString().padStart(2, '0')]);
    }

    return (
        <div style={{ padding: "15px" }}>
        <div style={{ padding: "15px",background:"#fafafa" }}>
            <h3 style={{textAlign:"center"}}>Visitors per day</h3>
            <LineChart
                xAxis={[
                    {
                        id: 'dayCategories',
                        data: xAxisData,
                        scaleType: 'band',
                    },
                ]}
                yAxis={[{
                    valueFormatter: (value) => (value === null ? 'N/A' : (value / 1000).toFixed() + 'k'),
                    min:minValue,
                    max:maxValue,
                }]}
                series={selectedMonths.map((monthYear) => ({
                    id: monthYear,
                    data: xAxisData.map(day => {
                        const dayData = groupedData[monthYear].find(d => d.day === day);
                        return dayData ? dayData.visitors : null;
                    }),
                    label: monthYear,
                    curve: 'monotoneX',
                }))}
                height={300}
            />

            <div style={{ display: "flex", flexDirection: "rows", gap: "10px", justifyContent:"space-around", background:"#fafafa"}}>
                {Object.keys(groupedByYear).sort().map((year) => (
                    <div key={year}>
                        <strong>
                            <label style={{cursor:"pointer"}} onClick={()=>selectAllMonths(groupedByYear[year])}>{months[year]}</label>
                        </strong>
                        {groupedByYear[year].map((monthYear) => (
                            <div key={monthYear} >
                                <input
                                    type="checkbox"
                                    value={monthYear}
                                    onChange={handleMonthSelection}
                                    checked={selectedMonths.includes(monthYear)}
                                />
                                <label style={{ marginLeft: "5px" }}>{monthYear.split('/')[1]}</label>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
}

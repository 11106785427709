import { LineChart } from '@mui/x-charts/LineChart';
import React, { useState } from "react";

function groupDataByMonth(data) {
    const groupedData = {};

    data.forEach((d) => {
        if (!groupedData[d.year]) {
            groupedData[d.year] = [];
        }

        groupedData[d.year].push({ month:d.month, visitors: d.visitors });
    });
    return groupedData;
}

export function VisitorPerMonthChart({ data }) {
    const groupedData = groupDataByMonth(data);
    const [selectedYears, setSelectedYears] = useState([]);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const xAxisData = Array.from({ length: 12 }, (_, i) => i);

    const selectYear = (year) => {
        if(selectedYears.includes(year)){
            setSelectedYears(selectedYears.filter((y) => y !== year));
        }else{
            setSelectedYears([...selectedYears, year]);
        }
    }
    const minValue = Math.min(...data.map(d => d.visitors));
    const maxValue = Math.max(...data.map(d => d.visitors));
    

    // on load, select the current month and the current month one year ago
    if (selectedYears.length === 0 && Object.keys(groupedData).length > 0) {
        setSelectedYears(Object.keys(groupedData));
    }
    const colors = [
        "rgba(0, 160, 0, 1)",
        "rgba(255, 0, 0, 0.4)", 
        "rgba(127, 0, 51, 0.2)", 
        "rgba(127, 127, 0, 0.1)", 
        "rgba(0, 127, 127, 0.1)", 
        "rgba(127, 127, 255, 0.2)", 
        "rgba(127, 85, 0, 0.2)", 
        "rgba(63, 0, 63, 0.2)", 
        "rgba(63, 127, 0, 0.2)", 
        "rgba(0, 63, 127, 0.2)", 
        "rgba(63, 0, 0, 0.2)", 
        "rgba(0, 63, 63, 0.2)" 
    ];
    

    return (
        <div style={{ padding: "15px" }}>
        <div style={{ padding: "15px",background:"#fafafa" }}>
            <h3 style={{textAlign:"center"}}>Visitors per month</h3>
            <LineChart
                xAxis={[
                    {
                        id: 'dayCategories',
                        data: xAxisData,
                        scaleType: 'band',
                        valueFormatter: (value) => months[value],
                    },
                ]}
                yAxis={[{
                    valueFormatter: (value) => (value === null ? 'N/A' : (value / 1000).toFixed() + 'k'),
                    min: minValue,
                    max: maxValue
                }]}
                series={selectedYears.filter((item)=>groupedData.hasOwnProperty(item)).map((year,index) => ( {
                    id: year,
                    data: xAxisData.map(month => {
                        const dayData = groupedData[year]? groupedData[year][month] : {visitors: null};
                        return dayData ? dayData.visitors : null;
                    }),
                    label: year,
                    curve: 'monotoneX',
                    color: colors[new Date().getFullYear() - year]
                }))}
                height={300}
            />

            <div style={{ display: "flex", flexDirection: "rows", gap: "10px", justifyContent:"space-around", background:"#fafafa"}}>
                {Object.keys(groupedData).sort().map((year) => (
                    <div key={year}>
                        <strong>
                            <label style={{cursor:"pointer"}} onClick={()=>selectYear(year)}>{year}</label>
                        </strong>
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import { DataTable } from "../components";
import { VisitorPerMonthChart } from "./visitorPerMonthChart";


const months = ["Januar","Febuary","March","April","May", "June", "July", "August", "September", "October", "November", "December"];
const columnItem = (props) =>{
    return <div className={props.className} style={{display:"flex", justifyContent:"space-between", flexDirection:"column-reverse"}}>
        {props.visitors}
        <span style={{fontSize:"small", textAlign:"right"}}>
            {props.diff ? props.diff.toFixed(2) + '%' : ""}
        </span>
    </div>
}
const transformData = (data, comparison) => {
    const ret = [];
    const all = [];
    const match = (j, m) => (a) => a[m] === j
    const formatNumber = (num) => {
        return Intl.NumberFormat('de-DE').format(num);
    }
    for(let i = 2019; i <= new Date().getFullYear(); i++ ){
        const yearRows = data.filter(match(i, "year"));
        if(yearRows.length > 0){
            const transformedRow = {
                year : <b>{i}</b>,
                ColumnClasses : {}
            };
            for(var j = 1; j <= 12; j++){
                const row =  yearRows.find(match(j, "month"));
                if(row){
                    all.push(row.visitors);
                    transformedRow[months[j - 1]] = formatNumber(row?.visitors || 0);
                    let visitor = formatNumber(row?.visitors || 0);
                    let diff = "";
                    if(comparison === "month"){
                        if(all.length > 1){
                            const prev = all[all.length - 2];
                            const now = all[all.length - 1];
                            diff = (100 * (now - prev) / prev);
                            if(diff < 0){
                                transformedRow['ColumnClasses'][months[j - 1]] = 'red-faded';
                            }else{
                                transformedRow['ColumnClasses'][months[j - 1]] = 'green-faded';
                            }
                            //transformedRow[months[j - 1]] += ` (${ diff.toFixed(2)} %)`
                        }
                    } else {
                        if(all.length > 12){
                            const prev = all[all.length - 13];
                            const now = all[all.length - 1];
                            diff = (100 * (now - prev) / prev);
                            if(diff < 0){
                                transformedRow['ColumnClasses'][months[j - 1]] = 'red-faded';
                            }else{
                                transformedRow['ColumnClasses'][months[j - 1]] = 'green-faded';
                            }
                            transformedRow[months[j - 1]] += ` (${ (100 * (now - prev) / prev).toFixed(2)} %)`
                        }
                    }
                    transformedRow[months[j - 1]] = columnItem({visitors:visitor, diff:diff});
                }
            }
            ret.push(transformedRow)
        }
    }
    return ret;
}

const OverviewTrafficTable = ({data,subHeader}) => {
    return <DataTable title="Range Overview" subHeader={subHeader} items={data} keys={["year"].concat(months)} />
}

export const OverviewTrafficAnalyzer = () => {
    const [y1, setY1] = useState(2019);
    const [y2, setY2] = useState(new Date().getFullYear());
    const [comparison, setComparison] = useState("month");
    const [data, setData] = useState(undefined);

    const years = [];
    for(let i = 2019; i <= new Date().getFullYear(); i++ ){
        years.push(i);
    }

    useEffect(() => {
        //setData(undefined);
        fetch(`/api/visitors/monthly_visitors/${y1}/${y2}`).then(resp => {
            if(resp.status === 200){
                resp.json().then(data => {
                    setData(data);
                })
            }else{
                setData(undefined);
            }
        })
    }, [y1, y2])
    const subHeader = (
        <div className="center-align overview-subheader">
            <b className="table-hidden">Overview Table</b>
            <i className="table-hidden">Select Range</i>
            <select onChange={e => setY1(e.target.value)} value={y1}>
                {years.map((m,i) => <option key={m} value={m}>{m}</option>)}
            </select>
            <select onChange={e => setY2(e.target.value)} value={y2}>
                {years.map((m,i) => <option key={m} value={m}>{m}</option>)}
            </select>
            <select onChange={e => setComparison(e.target.value)} value={comparison}>
                <option value={"month"}>By Month</option>
                <option value={"year"}>By Year</option>
            </select>
        </div>
    )
    return <div>
        
        {data ? undefined : subHeader}
        <div className="fullsize-table">
            {data ? <OverviewTrafficTable subHeader={subHeader} data={transformData(data, comparison)}/>: subHeader}
        </div>
        {data ? <VisitorPerMonthChart data={data}></VisitorPerMonthChart>: ""}
    </div>
}
import React from 'react';
import { DataTable } from './../../components';

const ServerDataTable = ({ data, getStatus, getStatusColor }) => {
    const keys = ["Server", "CPU", "Mem(Total|Free|Process|Used)", "Current Lag", "Max. Lag", "Min. Lag", "Uncaught Errors", "Status"];
    const rightJustify = ["Current Lag", "Max. Lag", "Min. Lag", "Uncaught Errors", "Status"];
    const tableData = [];

    if (data.servers) {
        data.servers.forEach((server) => {
            const serverData = {
                "Server": server.name,
                "CPU": server.responding ? server.cpuUsage.map((a) => `${a.usage}%`).join(" | ") : "",
                "Mem(Total|Free|Process|Used)": server.responding ? 
                    `${server.memmoryUsage.total.toFixed(0)}${server.memmoryUsage.unit} | ${server.memmoryUsage.free.toFixed(0)}${server.memmoryUsage.unit} | ${server.memmoryUsage.process.toFixed(0)}${server.memmoryUsage.unit} | ${server.memmoryUsage.percentUsed.toFixed(0)}%` 
                    : "",
                "Current Lag": server.responding ? server.eventLoopLag.current || -1 : 0,
                "Max. Lag": server.responding ? server.eventLoopLag.max || -1 : 0,
                "Min. Lag": server.responding ? server.eventLoopLag.min || -1 : 0,
                "Uncaught Errors": server.responding ? server.uncaughtErrors : 0,
                "Status": getStatus(server),
                "className": getStatusColor(getStatus(server))
            };
            tableData.push(serverData);
        });
    }

    return (
        <DataTable
            rightJustify={rightJustify}
            keys={keys}
            items={tableData}
        />
    );
};

export default ServerDataTable;

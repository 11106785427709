import React, { useState, useEffect } from 'react';
import EventPaginatedTable from '../Components/Events/EventPaginatedTable';
import { Container } from '@mui/material';
import EventMonitor from '../Components/Events/EventMonitor';

const Events = () => {
    
    const [data, setData] = useState({});
    const [category, setCategory] = useState([]);
    const [filterType, setFilterType] = useState('include');

    const refreshData = () => {
        fetch(`/api/events/monitor`)
            .then(response => response.json())
            .then(data => {
                setData(data);
            }).catch(error =>{
                console.error('Error fetching events:', error);
            });
    };

    useEffect(() => {
       refreshData();
    },[]); // Empty dependency array ensures this runs only once after the initial render

    const addCategory = (categoryName) => {
        if(category.includes(categoryName)){
            setCategory(category.filter(c => c !== categoryName));
        }else{
            setCategory([...category,categoryName]);
        }
    }

    return (
        <Container maxWidth="xl" sx={{marginBottom:"20px"}}>
            <EventMonitor events={data} filterNames={category} setFilterNames={addCategory} filterType={filterType} setFilterType={setFilterType}/>
            <EventPaginatedTable filterNames={category} setFilterNames={addCategory} filterType={filterType} />
        </Container>
    );
};

export default Events;

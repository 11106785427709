import React, {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TablePagination from '@mui/material/TablePagination';
import LinearProgress from '@mui/material/LinearProgress';
import Refresh from '@mui/icons-material/Refresh';


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.slug}</TableCell>
        <TableCell style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis", maxWidth:"30vw"}}>
          {row.error_message.length > 100 ? row.error_message.substring(0,100) +'...': row.error_message}
        </TableCell>
        <TableCell align="right">{row.time}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Error Message
              </Typography>
              <Box>
                <div style={{maxWidth:"100%", whiteSpace:"pre-line", wordBreak:"break-all", wordWrap:"break-word"}}>{row.error_message}</div>
              </Box>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                User Agent
              </Typography>
              <Box>
                {row.user_agent}
              </Box>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Url
              </Typography>
              <Box>
                {row.url}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange,isMore } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const refresh = (event) => {
    onPageChange(event,-1);
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
         <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
      <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!isMore}
        aria-label="next page"
      >
      <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={!isMore}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
      <IconButton 
        onClick={refresh}
        aria-label="refresh"
      >
        <Refresh/>
      </IconButton>
    </Box>
  );
}

export default function ErrorPaginatedTable(props) {
  const [errors, setErrors] = useState([]);
  const [page, setPage] = React.useState(0);
  const [isMore, setIsMore] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [refresh, setRefresh] = React.useState(false);
  const {filterNames,filterType} = props;

  const handleChangePage = (event, newPage) => {
    if(newPage < 0){
      setRefresh(!refresh);
    }else{
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
        const fetchErrors = async () => {
          console.log(filterNames)
            try {
              setIsLoading(true);
                const response = await fetch('/api/errors?'+ new URLSearchParams({
                    filter: filterNames,
                    filterType: filterType,
                    limit:rowsPerPage,
                    offset:page*rowsPerPage
                }),{
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    method: 'GET',

                });
                const data = await response.json();
                if (data.length < rowsPerPage){
                    setIsMore(false);
                } else {
                    setIsMore(true);
                }
                setErrors(data);
            } catch (error) {
                console.error('Error fetching errors:', error);
            } finally {
              setIsLoading(false);
            }
        };

        fetchErrors();
  }, [rowsPerPage,page,refresh,filterNames,filterType]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Slug</TableCell>
            <TableCell>Message</TableCell>
            <TableCell align="right">Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {errors.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
        <TableFooter>
        {isLoading ?  <TableRow>
            <TableCell style={{padding:"0",margin:"0"}} colSpan={6}>
              <LinearProgress/>
            </TableCell>
        </TableRow>:null
        }
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              colSpan={6}
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={(props)=>TablePaginationActions({...props,isMore})}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
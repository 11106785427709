export default function formatDate(date, compareDate = null) {
    if(typeof date === 'string'){
        date = new Date(date);
    }
    
    const currentDate = compareDate !== null ? compareDate instanceof Date ? compareDate : new Date(compareDate) : new Date();
    const diffInMilliseconds = currentDate - date;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    if(diffInSeconds < 60){
        return `${diffInSeconds} sec`;
    }
    else if (diffInMinutes < 60) {
        return `${diffInMinutes} min`;
    } else if (diffInHours < 24) {
        return diffInDays === 1 ? `${diffInHours} hour` : `${diffInHours} hours`;
    } else if (diffInDays < 7) {
        return diffInDays === 1 ? `${diffInDays} day` : `${diffInDays} days`;
    } else if (compareDate !== null){
        return diffInDays === 1 ? `${diffInDays} day` : `${diffInDays} days`;
    }
    else if (date.getFullYear() === currentDate.getFullYear()) {
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
        });
        return formattedDate;
    }
    else {
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
        return formattedDate;
    }
}
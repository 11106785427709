import React, { Component } from 'react';
import { Site, Routecontroller } from './components';
import { LoginGate, LogoutGate } from './Auth';
import { BrowserRouter } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { cake, deleteCake } from "../lib/util.js";

window.cake = cake;


/*
    The actual "app". This is the entry point, the top root of the tree. 
*/
class AdminApp extends Component {
    constructor() {
        super();
        //Get our logged in state. 
        let user = JSON.parse(cake("user"));
        if (user) {
            //We claim we are logged in, but I've ran in to some issues with the cookie not clearing after logging out
            //Put the user on a hold for a bit while we confirm with the server that our session is still logged in.
            this.state = { pending: true, isAuthenticated: true, user: user.user, token: user.token };
            fetch("/api/auth/isLoggedIn").then(data => data.json()).then(
                data => {
                    if (data.success && data.isLoggedIn) {
                        //We're logged in. Render page. 
                        this.setState({ pending: false });
                    } else {
                        //We're not logged in. Clear the cookie and start again.
                        deleteCake("user");
                        this.setState({ pending: false, isAuthenticated: false, user: null, token: null });
                    }
                }
            );
        } else {
            this.state = { pending:false, isAuthenticated: false, user: null, token: null };
        }
        this.authenticated = this.authenticated.bind(this);
        this.logout = this.logout.bind(this);
    }

    //Callback once the Server has verified our Google token 
    authenticated(authObject) {
        if (authObject.user && authObject.token) {
            this.setState({
                isAuthenticated: true,
                user: authObject.user,
                token: authObject.token
            });
        }
        let cookie = {
            user: authObject.user,
            token: authObject.token
        };
        cake("user", JSON.stringify(cookie), 31);
    }

    //Clear our cookie and change our state. 
    logout() {
        cake("user", null);
        deleteCake("user");
        this.setState({
            isAuthenticated: false,
            user: null,
            token: null 
        });
    }

    render() {
        let logout = <LogoutGate token={this.state.token} onLogout={this.logout} />;
        if (this.state.isAuthenticated) {
            if (this.state.pending) { //We think we're authenticated, but double checking.
                return (<BrowserRouter>
                    <Site links={this.props.links} user={this.state.user} logoutButton={logout} />
                    <Alert variant="warning">We're authenticating your login cookie. Please stand by.</Alert>
                </BrowserRouter>);
            } else {
                //We're logged in. Route the user correctly.
                return (
                    <BrowserRouter> 
                <Site links={this.props.links} user={this.state.user} logoutButton={logout} />
                <Routecontroller user={this.state.user} links={this.props.links} />
                </BrowserRouter>);
            }
        } else {
            //Not logged in. Show google login button. 
            return( <BrowserRouter>
                <Site links={[]} />
            <div id="log-in">
                <LoginGate  onAuth={this.authenticated} />
            </div>
            </BrowserRouter >);
        }
    }
}

export {AdminApp}; 

import React,{useState,useEffect} from 'react';
import { Spinner , Button } from 'react-bootstrap';
import ServerSumTable from './ServerSumTable'; // Adjust the path based on your directory structure
import ServerDataTable from './ServerDataTable'; // Adjust the path based on your directory structure
import ServerLoopGraph from './ServerLoopGraph';
import ServerGraphs from './ServerGraphs';

const RefreshButton = ({ onClick,pending }) => {
    return (
        <div className="fakecenter">
            {pending?
            <Spinner animation="border" variant="success" role="status" />:
            <Button className="smallTopBottomGap" onClick={onClick} variant="primary">Refresh</Button>
            }
        </div>
    );

}

const ServerTable = ({ pending, onFetch, data }) => {
    const [autoRefresh, setAutoRefresh] = useState(false);
    const getStatusColor = (status) => {
        switch(status) {
            case "DEAD":
            case "ERRORS":
                return "danger";
            case "SLOW":
                return "warning";
            case "OK":
                return "okGreen";
            default:
                return "";
        }
    };

   // Auto refresh every 10 seconds
    useEffect(() => {
        if (autoRefresh) {
            const interval = setInterval(() => {
                onFetch();
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [autoRefresh, onFetch]);
    

    const getStatus = (server) => {
        if (!server.responding) return "DEAD";
        if (server.uncaughtErrors) return "ERRORS";
        if (server.eventLoopLag.max > 15) return "SLOW";
        return "OK";
    };

    return (
        <div>
            <RefreshButton onClick={onFetch} pending={pending}/>
            <ServerSumTable data={data} getStatus={getStatus} getStatusColor={getStatusColor} />
            <ServerDataTable data={data} getStatus={getStatus} getStatusColor={getStatusColor} />
            <ServerLoopGraph data={data} />
            <ServerGraphs data={data} autoRefresh={autoRefresh} setAutoRefresh={setAutoRefresh} />
        </div>
    );
};

export default ServerTable;
import React from 'react';

import '../../css/WinRateTables.css';
import '../../css/bootstrap.min.css';
import {SumTable} from '../components.js';

function GameList(p){
    let game = null;
    if(p.gameList && p.gameList.length > 0){
        if(p.selected >= p.gameList.length){
            p.onNewGame(p.gameList.length);
        } else{
            game = p.gameList[p.selected].id;
        }
    }
    let gameList = p.gameList.slice();
    gameList.sort((a,b) => a.name > b.name);
    let list = gameList.map(x => {
        let bold = x.id === game ? "bold":"";
        return <span onClick={e => p.onNewGame(p.gameList.findIndex(y => y.id === x.id))} key={x.id} className={`gameLink ${bold}`}>{x.name}</span>;
    });
    return <div className="gameListContainer">{list}</div>;
}

function CompiledSumTable(p){
    let data = p.data;
    let keys = ["Winner", "Win%", "Total Wins"];
    let sums=["Win%","Total Wins"];
    if(data){
        data = data.sort((a,b) => b[1] - a[1]);
        data = data.map(x => {return {"Winner":x[0], "Win%":x[1], "Total Wins":x[2]};});
        return <SumTable  title = {p.title} sumDirection="rows" sumRowName="Total" items={data} keys={keys} sumColumns={sums}></SumTable>;
    }
    return null;
}

function NoGameHere(p){
    if(p.pending){        
        return <b className={p.className}><i>Loading Data...</i></b>;
    }
    if(!p.d1 && !p.d2){
        return <b className={p.className}><i>It appears no data exists for this game at this date.</i></b>;
    } else return null;
}

function WinrateTables(props){
    let game = props.gameList[props.selectedGame] || {name:"Please wait.."};
    return <div style={{position:"relative", "zIndex":0}}>
        <GameList gameList={props.gameList} selected={props.selectedGame} onNewGame={props.onNewGame} ></GameList>
        <h2 className="game-title">{game.name}</h2>
        <CompiledSumTable title="Single Wins" data={props.winData}></CompiledSumTable>
        <CompiledSumTable title="Tournament Wins" data={props.tournamentWinData}></CompiledSumTable>
        <br></br>
        <NoGameHere pending={props.pending || false} className="no-game-here" d1 = {props.winData} d2 = {props.tournamentWinData}></NoGameHere>
    </div>;
}

export default WinrateTables;

import React, {useState,useEffect, useRef} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Unstable_Grid2';
import NumberFormatter from '../Helpers/NumberFormatter';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
function Row(props) {
    const { row,setFilterNames } = props;
  
    return (
      <React.Fragment>
        <TableRow sx={{ cursor:"pointer"}} onClick={()=>setFilterNames(row.name)}>
          <TableCell >
            {row.name}
        </TableCell>
          <TableCell>
            {row.num}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
      
const SummaryTable = ({ errors, isLoading,setFilterNames}) => {
    const [page,setPage] = useState(0);
    const [perPage,setPerPage] = useState(10);
    const [paginated,setPaginated] = useState([]);
    
    useEffect(() => {
        setPaginated(errors.slice(page*perPage,page*perPage+perPage));
    },[page,perPage,errors]);

    return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginated.map((row) => (
            <Row key={row.name} row={row} setFilterNames={setFilterNames} />
          ))}
        </TableBody>
        <TableFooter>
        {isLoading ?  <TableRow>
            <TableCell style={{padding:"0",margin:"0"}} colSpan={2}>
              <LinearProgress/>
            </TableCell>
        </TableRow>:null
        }
          <TableRow>
            <TableCell></TableCell>
            <TablePagination 
            count={errors.length}
            rowsPerPage={perPage}
            page={page}
            onPageChange={(event,page)=>{setPage(page)}}
            onRowsPerPageChange={(event)=>{setPerPage(parseInt(event.target.value))}}
            ></TablePagination>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    );
};

export default function ErrorSummary(props) {
    const filterRef = useRef(null);
    if(!props.errors){
        return <div></div>
    }
    return (
        <Grid container spacing={3}>
            
            <Grid xs={12} lg={2}>
                <Stack spacing={4}>
                <Paper sx={
                    {
                        padding: "30px 15px",
                        textAlign: "center"
                    }
                }>
                    <Typography variant="h6" textAlign="center" >Total Errors</Typography> 
                    <FormControl variant="standard" sx={{marginLeft:"12px"}} size='small'>
                        <Select
                        value={props.summaryDate}
                        onChange={(e)=>{props.setSummaryDate(e.target.value)}}
                        sx={{fontSize:"14px"}}
                        >
                            <MenuItem value={1}>
                                Last hour
                            </MenuItem>
                            <MenuItem value={24}>
                                Last 24 hours
                            </MenuItem>
                            <MenuItem value={48}>
                                Last 48 hours
                            </MenuItem>
                            <MenuItem value={24*7}>
                                Last 7 days
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Typography variant='h4' textAlign="center" sx={{paddingTop:"15px"}}>{ NumberFormatter( props.total)}</Typography>
                </Paper>

                <Paper sx={
                    {
                        padding: "30px 15px",
                        textAlign: "center"
                    }
                }>
                    <Typography variant="h6" textAlign="center" >Filters</Typography> 
                    <FormControl variant="standard" sx={{marginBottom:"15px", marginLeft:"12px"}} size='small'>
                        <Select sx={{fontSize:"14px"}} value={props.filterType} onChange={(e)=>props.setFilterType(e.target.value)}>
                            <MenuItem value={"include"}>
                                Include
                            </MenuItem>
                            <MenuItem value={"exclude"}>
                                Exclude
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Stack directon="row" spacing={1}>
                    {props.filterNames.map((name)=><Chip key={name} label={name} onDelete={()=>props.setFilterNames(name)}></Chip>)}
                    </Stack>
                    <Divider sx={{margin:"15px 0"}}></Divider>
                    <Stack spacing={1}>
                        
                        <TextField label="Name" inputRef={filterRef} variant='standard' size='small'></TextField>
                        <Button variant='contained' onClick={()=>{
                            filterRef.current.value && props.setFilterNames(filterRef.current.value)
                            filterRef.current.value='';
                        }
                        }>Add</Button>
                        </Stack>
                </Paper>
                </Stack>
            </Grid>
            <Grid xs={12} lg={10}>
                <SummaryTable errors={props.errors} isLoading={false} setFilterNames={props.setFilterNames}></SummaryTable>
            </Grid>
        </Grid>
    )
}

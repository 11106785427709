import { LineChart } from '@mui/x-charts/LineChart';
import { useState } from "react";
const ServerLoopGraph = ({ data }) => {
    const [loopkey, setLoopkey] = useState('max');
    if(!Object.keys(data).length){
        return null;
    }
    
   const series = [];
   const loopKeys = []
   let maxLoopLength = 0;
   data.servers.forEach((server)=>{
         const serverData = server.eventLoopLag?.samples;
         if(serverData === undefined || serverData.length === 0) return;
         const serverName = server.name;
         if(loopKeys.length === 0){
            loopKeys.push(...Object.keys(serverData[0]));
         }
         const serverSeries = {
              id: serverName,
              data: serverData.map((d) => d[loopkey]),
              label: serverName,
              curve: 'monotoneX',
         };
         if(maxLoopLength < serverSeries.data.length){
            maxLoopLength = serverSeries.data.length;
         }
         series.push(serverSeries);
   });
    series.forEach((s)=>{
        if(s.data.length < maxLoopLength){
            const diff = maxLoopLength - s.data.length;
            for(let i = 0; i < diff; i++){
                s.data.unshift(null);
            }
        }
    });

   const Buttons = loopKeys.map((key) => (
    <button style={key === loopkey?{border:"1px solid green"}:{}} key={key} onClick={()=>setLoopkey(key)}>{key}</button>
    ));
    return (
        <div style={{ padding: "15px",background:"#fafafa" }}>
            <h3 style={{textAlign:"center"}}>Loop Lag</h3>
            <LineChart
                    xAxis={[ {scaleType: 'point', data:Array.from({ length: maxLoopLength }, (_, i) => (i-maxLoopLength) + 'H')}]}
                    series={series}
                    height={300}
            />
            <div style={{textAlign:"center"}}>
            {Buttons}
            </div>
        </div>
    );
}

export default ServerLoopGraph;
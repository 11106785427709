import React,{useState} from 'react';
import "../../css/phrases.css";
//import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Button } from 'react-bootstrap';
import { Component } from 'react';
import {DndContext,closestCenter,
    PointerSensor,
    useSensor,
    useSensors,} from '@dnd-kit/core';
    import {
        SortableContext,
        useSortable
      } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';



class DeleteButton extends Component{
    constructor(props){
        super();
        this.state={
            confirm:false,
            revertTime:10
        }
        this.interval = null;
        this.setInterval = this.setInterval.bind(this);
    }

    setInterval(){
        this.interval = setInterval(
            () => {
                let revertTime = this.state.revertTime;
                revertTime--;
                if(revertTime <= 0){
                    this.setState({
                        confirm:false,
                        revertTime:10
                    })
                    clearInterval(this.interval);
                    this.interval = null;
                } else{
                    this.setState({
                        revertTime:revertTime
                    })
                }
            },
            1000
        );
    }

    componentWillUnmount(){
        if(this.interval){
            clearInterval(this.interval);
        }
    }

    render(){
        if(this.state.confirm){
            return <td onClick={(e) =>{
                clearInterval(this.state.interval);
                this.props.onDelete(this.props.id);
            }} className="red"> Sure? ({this.state.revertTime})</td>
        } else{
            return <td onClick={ e => {
                this.setState({
                    confirm:true,
                    revertTime:10
                });    
                this.setInterval();
            }} className="red"> Delete</td> 
        }
    }
}

var style = {
    "border":"none",
    "width":"100%",
    "height":"2em",
    "textAlign":"center"
}

var bold = {
    "fontWeight":"bold"
}

const SortableItem = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {props.children}
      </div>
    );
  }

function _Phrase(props){
    return <div>
        <table className="phrase-table-item">
            <tbody>
                <tr className="phrase-table-row">
                    <td>
                        {props.nr}
                    </td>
                    <td>
                        {props.item.nr}
                    </td>
                    <td>
                        <input onChange={e => props.onChange(props.nr, "id", e.target.value)} style={style} placeholder="phrase-id here" value={props.item.id}></input>
                    </td>
                    <td>
                        <input onChange={e => props.onChange(props.nr, "text", e.target.value)} style={{...style, ...bold}} placeholder="Phrase Here" value={props.item.text}></input>
                    </td>
                    <td>
                        {props.item.type}
                    </td>
                    <DeleteButton id={props.item.id} onDelete={props.onDelete}></DeleteButton>
                </tr>
            </tbody>
        </table>
    </div>;
}

//const Phrase = SortableElement(_Phrase);
const Phrase = _Phrase;

function _PhraseList(props){
    
    const sensors = useSensors(
        useSensor(PointerSensor),
      );
    return <div className="phrase-table-table">
        <table>
            <tbody>
                        <tr className="phrase-table-row locked">
                    <td>
                        New nr
                    </td>
                    <td>
                       Old nr
                    </td>
                    <td>
                        Phrase ID
                    </td>
                    <td>
                        Phrase Text
                    </td>
                </tr>
            </tbody>
            </table>
            {props.reorder ? (
            <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={props.onSortEnd}
            >
                <SortableContext items={props.items.map((x,i)=>i+1)}>
                {props.items.map((x,i) => {
                    return <SortableItem id={i+1} key={x.id}><Phrase onChange={props.onChange} onDelete={props.onDelete} index={i} nr={i+1} key={i+"-"+x.nr} item={x}></Phrase> </SortableItem>
                })}
                </SortableContext>
            </DndContext>
          ):props.items.map((x,i) => {return <Phrase onChange={props.onChange} onDelete={props.onDelete} index={i} nr={i+1} key={i+"-"+x.nr} item={x}></Phrase>})
        }
            
    </div>
}

//const PhraseList = SortableContainer(_PhraseList);
const PhraseList = _PhraseList;

const SaveButton = (props) => {
    if(props.onSave){
        return <Button variant="success" className="phrase-table-save" onClick={props.onSave}>Save</Button>
    } 
    return null;
}

const NewButton = (props) => {
    if(props.onNew){
        return <Button variant="success" className="phrase-table-save" onClick={props.onNew}>New Phrase</Button>
    } 
    return null;
}

function PhraseTable(props){
    const [reorder, setReorder] = useState(false);
    return <div className="phrase-table">
        <h3>{props.title || ""}</h3>
        <PhraseList reorder={reorder} onChange={props.onChange} onDelete={props.onDelete} distance={5} title={props.title} onSortEnd={props.onSortEnd} items={props.items}></PhraseList>
        <SaveButton onSave={props.onSave}></SaveButton>
        <NewButton onNew={props.onNew}></NewButton>
        <Button variant={!reorder?"success":"warning"} onClick={e => setReorder(!reorder)}>{!reorder?"Enable":"Disable"} Reorder</Button>
    </div>
}

export default PhraseTable;
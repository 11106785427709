import React, { useEffect, useState } from "react";
import '../../css/simpleadmin.css';
import '../../css/bootstrap.min.css';

const genCode = (n) => {
    const alph = "QWERTYUIOPASDFGHJKLZXCVBNM1234567890";
    let code = "";
    for(let i = 0; i < n; i++){
        code += alph[Math.floor(Math.random() * alph.length)];
    }
    return code;
}

const createAdCode = (e, setAdCodes, setCode) => {
    if(!e.username || !e.created || !e.expires){
        return false;
    }
    e.code = genCode(5);
    fetch("/api/adfree/", {
        method: "POST",
        body : JSON.stringify(e),
        headers: {
            'content-type': 'application/json',
        },
    }).then(resp => {
        if(resp.status === 200){
            resp.json().then(code => {
                setCode(code.code);
                getCodes(setAdCodes);
            })
        }
    })
}

const SECOND = 1000;
const DAY = 86400 * SECOND;
const YEAR = DAY * 365;

const getCodes = (setAdCodes) => {
    const today = new Date();
    fetch("/api/adfree/").then(resp => {
        if(resp.status === 200){
            resp.json().then(data => {
                data = data.filter(d => d.status !== "expired" ||
                    new Date(d.created).getTime() + YEAR > today
                )
                setAdCodes(data);
            })
        }
    })
};

export const AdFree = (e) => {

    const [adCodes, setAdCodes] = useState([]);
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [created, setCreated] = useState(new Date());
    const [expires, setExpired] = useState(new Date());

    useEffect(() => getCodes(setAdCodes), []);


    return <div>
        <h1>Generate Ad Free Code</h1>
        <div>
            <p>
                This form can be used to generate a code that users can enter into <a href="https://cardgames.io/adfree" target="blank">cardgames.io/adfree</a>. Upon entering it it will disable all ads for that user until the code expires.
            </p>
            <form>
                <label>Name</label>
                <input value={name} onChange = {(e) => setName(e.target.value)}></input>
                <label>Created</label>
                <input  value={created} onChange = {(e) => setCreated(e.target.value)} type='date'></input>
                <label>End date</label>
                <input  value={expires} onChange = {(e) => setExpired(e.target.value)} type='date'></input>
                <button onClick={e => {
                    e.preventDefault();
                    createAdCode(
                        {
                            code : code,
                            username : name, 
                            created: created,
                            expires: expires
                        },
                        setAdCodes,
                        setCode
                    );
                }}>Create Code</button>
            </form>
            
            <b>Your code:</b>
            <b>{code}</b>
        </div>
        <div>
            <h2>Active codes</h2>
            <table>
                <thead>
                    <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Created</th>
                    <th>Expires</th>
                    <th>Activated</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {adCodes.map(code => <tr>
                        <td>{code.code}</td>
                        <td>{code.username}</td>
                        <td>{code.created}</td>
                        <td>{code.expires}</td>
                        <td>{code.activated}</td>
                        <td>{code.status}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>
}
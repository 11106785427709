/*
    components.js
    stores the React components.
    Try to design your components so that they can be used in more than one situation.
    Components ideally should do only one thing.
    If a component is doing many things, or is starting to get so complicated it is starting
    to get hard to read consider splitting it up in to multiple components that work together.
*/

import React, { Component } from 'react';
import '../css/simpleadmin.css';
import '../css/bootstrap.min.css';
import { Spinner , Navbar, NavDropdown, Table, Button, ButtonGroup, FormControl, Form, Card, Container, Row } from 'react-bootstrap';
import {  Route, Routes } from 'react-router-dom';
import { AgeService } from '../lib/util'; 
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-date-picker';


function DatePick(props){
    let incrementDay = (date, inc) => {
        date.setDate(date.getDate() + inc);
        return date;
    };
    return <div className="datePicker">
            <div className="datePicker-picker">                
            <Button className="dateArrow right" onClick={(e) => props.onDateChange(incrementDay(props.date, +1))} variant="secondary">Day Later</Button>
            <Button className="dateArrow left" onClick={(e) => props.onDateChange(incrementDay(props.date, -1))} variant="secondary">Day Earlier</Button>
            <DatePicker className="dateForm" value={props.date} onChange={(e) => props.onDateChange(e)} /><br />       

            </div>
            <Button className="dateFetch smallTopBottomGap" onClick={props.onFetch} variant="primary">Refresh</Button>
        </div>;
}

//Routing

// Routecontroller
// handles routing, displaying the correct "main page" depending on the url
// Props: links. An array of possible routes, routes are dicts that should have the keys "link" and "component"
function Routecontroller(prop) {
    const links = prop.links;
    //Maps the links to a Route component
    const linkItems = links.map(({ link, component:C}) =>
        <Route
            key={link}
            path={link}
            element={<C user={prop.user} />}
            //render={(props) => <C {...props} user={prop.user} />}
        />
    );
    //Renders the correct page.
    return (<div id="mainsite">
        <Routes>
        {linkItems}
        </Routes>
    </div>
    );
}


// Site
// renders the shared components of all pages, most notible the Navigation bar
// Props: links. An array of possible routes, routes are dicts that should have the keys "link" and "name"
class Site extends Component {
    constructor(props) {
        super(props);
        this.state = { isMenuOpen: false };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(state => ({
            isMenuOpen: !state.isMenuOpen
        }));
    }

    isLogoutButton() {
        if (this.props.user && this.props.logoutButton) {
           return true;
        } 
        return false;
    }
    
    render() {
        let showLogout = this.isLogoutButton();
        let logoutButton;
        let links = this.props.links;
        let dropdownMenu = links.map(x => 
                <a href={x.link} key={x.name}>{x.name}</a>
            ); 
        dropdownMenu.splice(-1);
        

        if (showLogout) {
            let name = this.props.user.name.split(" ")[0];
            logoutButton = <NavDropdown className="dropdown navbar-text" title={name} id="user-dropdown">
                {this.props.logoutButton} </NavDropdown>;
        } else {
            logoutButton = null;
        }
        return (
            <div>
                <Navbar expand="sm" fixed="top" className="CardgamesGreen" text="dark">
                    <button id="mobile-menu" onClick={this.handleClick} className={this.state.isMenuOpen ? 'menu-close' : ''  }>
                        <span className="menu-row-1"></span>
                        <span className="menu-row-2"></span>
                        <span className="menu-row-3"></span>
                    </button>            
                    {logoutButton}
                </Navbar>
                <div id="wrapper" className={this.state.isMenuOpen ? 'menu-open' : ''}>
                    <div id="mobile-options">
                        {this.state.isMenuOpen && dropdownMenu}
                    </div>
                </div> 
            </div>
        );
    }
}


//Games
class GameOverviewTable extends Component {
    constructor(props) {
        super(props);
        this.keys = [  "Game", "Type", "Release Date", "Age", "Time to build"];
    }
    
    formatData(data) {
        let finalData = [];
        for (let entry of data) {
            let finalEntry = {};
            finalEntry.id = entry.id;
            finalEntry.Game = <a href={entry.url}>{entry.name}</a>;
            finalEntry.Type = entry.type;
            finalEntry['Release Date'] = new Date(entry.release_date).toLocaleDateString();
            finalEntry.realReleaseDate = entry.release_date;
            let age = AgeService.getAge(entry.release_date);
            let ageString = AgeService.getAgeString(age);
            finalEntry.Age = ageString;
            finalData.push(finalEntry);
        }
        finalData.sort(function (a, b) { return a.id - b.id; });
        let lastEntry = null;
        for (let d of finalData) {
            if (d.id >= 3 && lastEntry) {
                let currAge = d.realReleaseDate;
                let oldAge = lastEntry.realReleaseDate;
                let age = AgeService.getTimeDifference(currAge, oldAge);
                let ttb = AgeService.getAgeString(age);
                d['Time to build'] = ttb;
            } else {
                d['Time to build'] = "N/A";
            }
            lastEntry = d;
        }
        return finalData;
    }

    render(props) {
        let data = this.formatData(this.props.data);
        return (
            <div>
                <DataTable title="Games" items={data} keys={this.keys} />
                <NewGameForm onPost={this.props.onPost}/>
            </div>
        );
    }
}

//Table showing games by a specified category. 
class GameCategoryTable extends Component {
    formatData(data, category, sortKey) {
        let finalData = [];
        let categories = {};
        for (let game of data) {
            let category = game[sortKey];
            if (!(category in categories)) {
                categories[category] = [];
            }
            categories[category].push(game.name);
        }
        for (let cat in categories) {
            let games = categories[cat];
            let row = {};
            row[category] = cat;
            row['Nr. of games'] = games.length;
            row['Games'] = games.join(", ");
            finalData.push(row);
        }
        return finalData;
    }

    render(props) {
        let category = this.props.category;
        let keys = [category, "Nr. of games", "Games"];
        let sortKey = this.props.sortKey;
        let data = this.formatData(this.props.data, category, sortKey);
        return (
            <DataTable title={`Games by ${this.props.category}`} items={data} keys={keys} />
        );
    }
}

class NewGameForm extends Component {
    constructor(props) {
        super(props);
        const startDate = new Date();
        this.state = { name: "", slug: "", url: "", type: "solitaire", release_date: startDate };
        this.submit = this.submit.bind(this);
    } 
    submit() {
        this.props.onPost(this.state);
    }
    render() {
        //Forms, such a beautiful mess even in React. 
        //It's at least fairly readable. 
        return (
            <ToggleForm submit={this.submit} title="New Game">
                <Form.Label>Name</Form.Label>
                <FormControl onChange={e => this.setState({ name: e.target.value })} value={this.state.name} placeholder={`Enter Game Name Here`} />
                <Form.Label>Slug</Form.Label>
                <FormControl onChange={e => this.setState({ slug: e.target.value })} value={this.state.slug} placeholder={`example`} />
                <Form.Label>Url</Form.Label>
                <FormControl onChange={e => this.setState({ url: e.target.value })} value={this.state.url} placeholder={`https://cardgames.io/example/`} />
                <Form.Label>Game Type</Form.Label>
                <Form.Control onChange={e => this.setState({ type: e.target.value })}  as="select">
                    <option value='solitaire'>Solitaire</option>
                    <option value='cardgame'>Cardgame</option>
                    <option value='puzzle'>Puzzle</option>
                    <option value='dicegame'>Dice Game</option>
                </Form.Control>
                <Form.Label>Release Date</Form.Label><br/>
                <DatePicker value={this.state.release_date} onChange={e => this.setState({ release_date: e })} /><br/>
            </ToggleForm>
        );
    }
} 

//Solitaire stats
class SolitaireTable extends Component {
    constructor(props) {
        super(props);
        this.keys = ["Game", "Variation", "Won", "Lost", "Unplayed", "Winnable %"];
        this.rightJust = ["Won", "Lost", "Unplayed", "Winnable %"];
    }

    formatData(data) {
        let finalData = [];
        for (let entry of data) {
            let finalEntry = {};
            finalEntry.id = entry.id;
            finalEntry.Game = entry.name;
            finalEntry.Variation = entry.variation;
            finalEntry.Won = entry.won;
            finalEntry.Lost = entry.lost;
            finalEntry.Unplayed = entry.unplayed;
            finalEntry['Winnable %'] = entry.winnable_percent;

            finalData.push(finalEntry);
        }
        finalData.sort(function (a, b) {
            if (a.game === b.game) {
                return a.Variation < b.Variation;
            } else {
                return a.Game < b.Game;
            }
        });
        return finalData;
    }

    render(props) {
        let data = this.formatData(this.props.data);
        return (
            <DataTable rightJustify={this.rightJust} title="Solitaire Statistics" items={data} keys={this.keys} />
        );
    }
}

//Multiplayer stats
function MultiplayerStatTable(props) {
    let datepicker = (
        <div>
            <DatePicker value={props.date} onChange={(e) => props.onDateChange(e)} /><br />
            <Button className="smallTopBottomGap" onClick={props.onFetch} variant="primary">Fetch</Button>
        </div>
    );

    let spinner = props.pending ? (<Spinner animation="border" variant="success" role="status" />) : null;

    let t1 = function () {
        let data = props.data;
        let keys = ["Game", "Started", "Finished", "In Progress"];
        let rightJust = ["Started", "Finished", "In Progress"];
        let tableData = [];
        for (let name in data) {
            let game = data[name];
            let gameDict = {};
            gameDict["Game"] = name;
            gameDict["Started"] = game["startedGames"];
            gameDict["In Progress"] = game["inProgressGames"];
            gameDict["Finished"] = game["endedGames"];
            tableData.push(gameDict);
        }
        return (
            <SumTable rightJustify={rightJust} sumDirection="rows" keys={keys} items={tableData} sumRowName="All" sumColumns={rightJust} nameColumn="Game" sortRowsBy="Game" />
        );
    };

    let t2 = function () {
        let data = props.data;
        let keys = ["Game"];
        let tableData = [];
        for (let name in data) {
            let game = data[name];
            let entrant = {};
            entrant["Game"] = name;
            for (let reason in game.endReasons) {
                if (keys.indexOf(reason) === -1) {
                    keys.push(reason);
                }
                entrant[reason] = parseInt(game.endReasons[reason]);
            }
            tableData.push(entrant);
        }
        let rightJust = keys.slice(1);
        
        return (
            <SumTable rightJustify={rightJust} sumDirection="both" keys={keys} items={tableData} sumColumnName="Total" sumColumns={rightJust} sumRowName="All" nameColumn="Game" defaultValue={0} sortRowsBy="Game"/>
        );
    };

    return (
        <div>
            <div className="fakecenter">
                {datepicker}
                {spinner}
            </div>
            {t1()}
            {t2()}
        </div>
    );
}

//Multiplayer servers
function ServerTable(props) {
    let refresh = function () { 
    let spinner = props.pending ? (<Spinner animation="border" variant="success" role="status" />) : null;
    return (<div>
        <Button className="smallTopBottomGap" onClick={props.onFetch} variant="primary">Refresh</Button><br/>
        {spinner}
    </div>);
    };

    let getStatusColor = function (status) {
        if (status === "DEAD" || status === "ERRORS") {
            return "danger";
        }
        if (status === "SLOW") {
            return "warning";
        }
        if (status === "OK") {
            return "okGreen";
        }
    };

    let getStatus = function (server) {
        if (!server.responding) {
            return "DEAD";
        }
        if (server.uncaughtErrors) {
            return "ERRORS";
        }
        if (server.eventLoopLag.max > 15) {
            return `SLOW`;
        }
        return "OK";
    };
    
    let t1 = () => {
        let data = props.data;
        let keys = ["Server", "Start Time", "Runtime", "Connected", "Max", "Tables", "Status"];
        let rightJust = ["Connected", "Max", "Tables", "Status"];
        let sum = ["Connected", "Max", "Tables"];
        let tableData = [];
        if (data.servers) {
            for (let server of data.servers) {
                let serv = {};
                serv["Server"] = server.name;
                if (server.responding) {
                    serv["Start Time"] = new Date(server.startTime).toLocaleString();
                    let age = AgeService.getAge(server.startTime);
                    //console.log(age);
                    let runTime = AgeService.getAgeString(age, true);
                    if (runTime.length > 31) {
                        runTime = AgeService.getAgeString(age, true, true);
                    }
                    serv["Runtime"] = runTime;
                    serv["Connected"] = server.connectedCount.current || 0;
                    serv["Max"] = server.connectedCount.max || 0;
                    serv["Tables"] = server.tables || 0;
                } else { //This server did not respond, but a server above it did. Display an error. 
                    serv["Server"] = server.name;
                    serv["Start Time"] = "UNKNOWN";
                    serv["Runtime"] = "UNKNOWN";
                    serv["Connected"] = 0;
                    serv["Max"] = 0;
                    serv["Tables"] = 0;
                }
                let status = getStatus(server);
                serv["Status"] = status;
                serv["className"] = getStatusColor(status);

                tableData.push(serv);
            }
        }
        return (
            <SumTable defaultValue={""} rightJustify={rightJust} sumColumns={sum} sumDirection="rows" keys={keys} items={tableData} sumRowName="Total" nameColumn="Server"/>
        );
    };

    let t2 = () => {
        let data = props.data;
        let keys = ["Server","CPU","Mem(Total|Free|Proccess|Used)", "Current Lag", "Max. Lag", "Min. Lag", "Uncaught Errors", "Status"];
        let rightJust = ["Current Lag", "Max. Lag", "Min. Lag", "Uncaught Errors","Status"];
        let tableData = [];
        if (data.servers) {
            for (let server of data.servers) {
                let serv = {};
                serv["Server"] = server.name;
                if (server.responding) {
                    serv["Start Time"] = new Date(server.startTime).toLocaleString();
                    let age = AgeService.getAge(server.startTime);
                    //console.log(age);
                    let runTime = AgeService.getAgeString(age, true);
                    if (runTime.length > 31) {
                        runTime = AgeService.getAgeString(age, true, true);
                    }
                    serv["CPU"] = server.cpuUsage.map((a) => a.usage+'%').join(" | ");
                    serv["Mem(Total|Free|Proccess|Used)"] = server.memmoryUsage.total.toFixed(0)+server.memmoryUsage.unit+' | '+ server.memmoryUsage.free.toFixed(0)+server.memmoryUsage.unit +' | '+server.memmoryUsage.process.toFixed(0)+server.memmoryUsage.unit +' | '+server.memmoryUsage.percentUsed.toFixed(0)+'%';
                    serv["Current Lag"] = server.eventLoopLag.current || -1;
                    serv["Max. Lag"] = server.eventLoopLag.max || -1;
                    serv["Min. Lag"] = server.eventLoopLag.min || -1;
                    serv["Uncaught Errors"] = server.uncaughtErrors;
                    console.log(serv["Uncaught Errors"]);
                } else { //This server did not respond, but a server above it did. Display an error. 
                    serv["Current Lag"] = 0;
                    serv["Max. Lag"] = 0;
                    serv["Min. Lag"] = 0;
                    serv["Uncaught Errors"] = 0;
                }
                let status = getStatus(server);
                serv["Status"] = status;
                serv["className"] = getStatusColor(status);
                tableData.push(serv);
            }
        }
        return (
            <DataTable rightJustify={rightJust} keys={keys} items={tableData} />
        );
    };

    return (
        <div>
            <div className="fakecenter">
                {refresh()}
            </div>
            {t1()}
            {t2()}
        </div>
    );
}

function formatNumber(num) {
    return new Intl.NumberFormat('de-DE').format(String(num));
}

function calculateIncreaseOrDecrease(originalNum, newNum) {
    if (originalNum && newNum) {
        let difference = Math.abs(originalNum - newNum);
        let percentage = (difference/originalNum) * 100;
        return parseFloat(percentage.toPrecision(1));
    } else {
        return '';
    }
    
}


//Traffic table
function TrafficTable(props) {
    let data = props.data;
 
    let today = data.today ? formatNumber(data.today) : 'Loading...';
    let yesterday = data.yesterday ? formatNumber(data.yesterday) : 'Loading...';
    let yesterdaySameDayLastWeek = data.yesterdaySameDayLastWeek ? formatNumber(data.yesterdaySameDayLastWeek) : 'Loading...';
    let thisMonth = data.thisMonth ? formatNumber(data.thisMonth) : 'Loading...';
    let lastMonth = data.lastMonth ? formatNumber(data.lastMonth) : 'Loading...';
    let monthBeforeLast = data.monthBeforeLast ? formatNumber(data.monthBeforeLast) : 'Loading...';

    return (
        
        <Container>
            <Row>
                <Card className="card-style">
                    <Card.Body>
                        <Card.Text>Today so far:</Card.Text>
                        <Card.Text className="big-number">{today}</Card.Text>
                    </Card.Body>
                </Card>

                <Card className="card-style">
                    <Card.Body>
                        <Card.Text className="card-title">Yesterday <i className="card-italic">vs the same day last week:</i></Card.Text>
                        <Card.Text className="big-number">{yesterday}</Card.Text>
                        <Card.Text className={(data.yesterday - data.yesterdaySameDayLastWeek) < 0 ? 'color-red' : 'color-green'}>
                            {data.yesterday >= data.yesterdaySameDayLastWeek && <span>&uarr;</span>} {" "}
                            {data.yesterday < data.yesterdaySameDayLastWeek && <span>&darr;</span>} {" "}
                            {yesterdaySameDayLastWeek} {" "}
                            ({calculateIncreaseOrDecrease(data.yesterdaySameDayLastWeek, data.yesterday)}%)
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Card className="card-style">
                    <Card.Body>
                        <Card.Text className="card-title">This month so far:</Card.Text>
                        <Card.Text className="big-number">{thisMonth}</Card.Text>
                    </Card.Body>
                </Card>
            </Row>
            <Row>

                <Card className="card-style">
                    <Card.Body>
                        <Card.Text className="card-title">Last month <i className="card-italic">vs the month before last:</i></Card.Text>
                        <Card.Text className="big-number">{lastMonth}</Card.Text>
                        <Card.Text className={(data.lastMonth - data.monthBeforeLast) < 0 ? 'color-red' : 'color-green'}>
                            {data.lastMonth >= data.monthBeforeLast && <span>&uarr;</span>} {" "}
                            {data.lastMonth < data.monthBeforeLast && <span>&darr;</span>} {" "}
                            {monthBeforeLast} {" "}
                            ({calculateIncreaseOrDecrease(data.monthBeforeLast, data.lastMonth)}%)
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
        
    );
}


//Bad word table
function BadWordTable(props){
    let keys = ["Bad Word", "Hit Count", "Delete"];
    let formatData = function (data, onDelete) {
        let finalData = [];
        for (let entry of data) {
            let finalEntry = {};
            let delFunc = function () {
                onDelete(entry.word);
            };
            finalEntry["Bad Word"] = entry.word;
            finalEntry["Hit Count"] = entry.hit_count;
            finalEntry["Delete"] = <ConfirmButton size="sm" onClick={delFunc} variant="primary">Delete</ConfirmButton>;

            finalData.push(finalEntry);
        }
        return finalData;
    };
    
    let data = formatData(props.data, props.onDelete);
    return (
        <DataTable title="Solitaire Statistics" items={data} keys={keys} />
    );
}

class ConfirmButton extends Component {
    constructor(props) {
        super(props);
        this.state = { confirmPrompt: false, timer: 0 };
        this.tick = this.tick.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    confirm() {
        this.setState({ confirmPrompt: true, timer: 10 });
        setTimeout(this.tick, 1000);
    }

    tick() {
        if (this.state.confirmPrompt) {
            if (this.state.timer <= 1) {
                this.setState({ confirmPrompt: false, timer: 0 });
            } else {
                this.setState({timer: this.state.timer - 1 });
                setTimeout(this.tick, 1000);
            }

        }
    }

    render() {
        if (this.state.confirmPrompt) {
            return <Button size={this.props.size} onClick={this.props.onClick} variant="danger">Sure? ({this.state.timer})</Button>;
        } else {
            return <Button size={this.props.size} onClick={this.confirm} variant="primary">Delete</Button>;
        }
    }
}

class BadWordForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 'badWord':"" };
        this.submitWord = this.submitWord.bind(this);
    }
    submitWord() {
        this.props.onPost(this.state.badWord);
        this.setState({'badWord':"" });
    }
    render() {
        return (
            <ToggleForm submit={this.submitWord} title="New Bad Word">
                <Form.Label>Bad Word</Form.Label>
                <FormControl onChange={e => this.setState({ badWord: e.target.value })} value={this.state.badWord} placeholder={`#!$?*&`} />
            </ToggleForm>
        );
    }
}

//Revenue

//A toolbar for currencies.
// Props:
// currencies: list of currency codes to display
// selected: Which one should have a green button.
function CurrencySelector(props) {
    let buttons = props.currencies.map(x => {
        let col;
        col = props.selected === x ? "success" : "secondary";
        return <Button onClick={() => props.onSelect(x)} key={x} variant={col}>{x}</Button>;
    });
    return <ButtonGroup className="centered">{buttons}</ButtonGroup>;
}

//revenue table
//shows two tables, one for averages and one for current stats.
//props
// data: Revenue data
// currency: the code of the current currency
// ratio: The convertion rate from the base currency
function RevenueTable(props) {
    //Return nothing if we have no data. 
    if (props.data.length === 0) {
        return <div/>;
    }
    //Ta
    let convert = function (amount) {
        return Math.round((amount * props.ratio)).toLocaleString();
    };
    //Current stats
    let t1 = function () {
        let today = new Date();
        today.setHours(0,0,0,0);
        const DAY = 24 * 60 * 60 * 1000;
        let yesterday = new Date(today.getTime() - DAY);
        let thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        let data = props.data;
        let Currency = props.currency;
        let thisMonth = today.getMonth();
        let lastMonth = (thisMonth + 11) % 12;
        let keys = ["Period", "Amount", "Currency"];
        let items = []; 

        let yesterdayEarning = data.filter(x => +(new Date(x.date)) === +yesterday).reduce((a, b) => a + b.estimated_earnings, 0);
        let todayEarning = data.filter(x => +(new Date(x.date)) === +today).reduce((a, b) => a + b.estimated_earnings, 0);
        let thisMonthEarning = data.filter(x => new Date(x.date).getMonth() === thisMonth).reduce((a, b) => a + b.estimated_earnings, 0);
        let lastMonthEarning = data.filter(x => new Date(x.date).getMonth() === lastMonth).reduce((a, b) => a + b.estimated_earnings, 0);
        let last30Days = data.filter(x => new Date(x.date) >= thirtyDaysAgo).reduce((a, b) => a + b.estimated_earnings, 0);

        items.push({ Period: "Today", Amount: convert(todayEarning), Currency });
        items.push({ Period: "Yesterday", Amount: convert(yesterdayEarning), Currency });
        items.push({ Period: "This month so far", Amount: convert(thisMonthEarning), Currency });
        items.push({ Period: "Last month", Amount: convert(lastMonthEarning), Currency });
        items.push({ Period: "Last 30 days", Amount: convert(last30Days), Currency });

        return <DataTable rightJustify={["Amount"]} size="sm" title="Average Per Day" items={items} keys={keys} />;
    };
    //Average stats
    let t2 = function () {
        let fullWeeks = [];
        let d = new Date(props.data[0].date);
        let weekday = d.getDay();
        let keys = ["Period", "Amount", "Currency"];
        //Collect the last 5 weeks
        for (let i = 0; i < props.data.length; i++) {
            if (weekday === 1) {
                for (let x = 0; x < 5; x++) {
                    if (i + 7 * (1 + x) >= props.data.length) {
                        break;
                    }
                    let avg = 0;
                    let lastDay = new Date(props.data[i + 7 * x].date);
                    let firstDay = new Date(props.data[i + 7 * x + 6].date);
                    for (let j = 0; j < 7; j++) {
                        avg += props.data[i + 7 * x + j].estimated_earnings;
                    }
                    let Period = firstDay.toString().substring(4, 10) + " - " + lastDay.toString().substring(4, 10);
                    let Amount = convert(avg / 7);
                    let week = { Period, Amount, Currency: props.currency };
                    fullWeeks.push(week);
                }
                break;
            } else {
                weekday = (weekday + 11) % 12;
            }
        }
        return <DataTable rightJustify={["Amount"]} size="sm" title="Average Per Day" items={fullWeeks} keys={keys} />;
    };
    
    return ( < DataTableContainer size="md" >
        {t1()}
        {t2()}
    </DataTableContainer>);
}

function TopRevenueTable(props) {
    let items = [];
    let keys = ["Date", "Amount","Currency" ];
    for (let row of props.data) {
        let item = {};
        let date;
        if ("date" in row) {
            date = new Date(row.date);
            date = date.toLocaleDateString();
        } else {
            date = row.month_name + " " + row.year;
        }
        let Amount = Math.round((row.estimated_earnings * props.ratio)).toLocaleString();
        let Currency = props.currency;
        item = { Date:date, Amount,Currency  };
        items.push(item);
    }
    return <DataTable rightJustify={["Amount"]} size="sm" title={props.title} items={items} keys={keys} />;
} 

//Misc 

//Toggleform
//A wrapper around a form that allows it to be toggled on and off, as well as submitted.
class ToggleForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 'visible': false };
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.submit = this.submit.bind(this);
    }
    toggleVisibility() {
        this.setState({ visible: !this.state.visible });
    }
    submit() {
        this.props.submit();
        this.setState({ visible: false });
    }
    render() {
        if (this.state.visible) {
            return (
                <div className="centered center-table">
                    <Form>
                        {this.props.children}
                        <Button className="formButton" onClick={this.submit}>Save</Button>
                        <Button className="formButton" onClick={this.toggleVisibility}>Cancel</Button>
                    </Form>
                </div>
            );
        } else {
            return (<div className="centered center-table"><Button onClick={this.toggleVisibility}>{this.props.title}</Button></div>);
        }
    }
}

//Datatable
//Generic table to display data 
//Props
// keys: Array of the headings of the colums
// Items: Array of dicts that have matching keys to the props.keys and the data to be displayed. 
class DataTable extends Component {
    getHeader() {
        let rightJustified = this.props.rightJustify || [];
        const header = this.props.keys.map((x) => {
            let classes = "";
            if (rightJustified.indexOf(x) >= 0) {
                classes += " rightJustified ";
            }
            return (<th className={classes} key={x}>{x}</th>);
        });
        return (<thead >
            <tr>
                {header}
            </tr>
        </thead >);
    }
    getRows() {
        const rows = [];
        let ind = 0;
        //Generates the rows of the column
        if (this.props.sortRowsBy) {
            let sorter = this.props.sortRowsBy;
            let sortKeys = this.props.items.map(x => x[sorter]);
            sortKeys.sort();
            this.props.items.sort(function (a, b) { return sortKeys.indexOf(a[sorter]) - sortKeys.indexOf(b[sorter]); });
        }
        for (let i of this.props.items) {
            for (let k of this.props.keys) {
                if(i[k] == null)
                    i[k] = (i[k] !== null) ? i[k] : this.props.defaultValue;
            }
            let columns = [];
            //Get the elements of the object
            let className = i["className"] || "";
            for (let key in i) {
                //Does this fit in any of our columns?
                if (this.props.keys.indexOf(key) !== -1) {
                    let className = i.ColumnClasses ? i.ColumnClasses[key] || "" : "";
                    columns.push({ "key": key, className, "val":i[key]});
                }
            }
            let t = this;
            let rightJust = this.props.rightJustify || [];
            columns.sort(function (a, b) { return t.props.keys.indexOf(a.key) - t.props.keys.indexOf(b.key); });
            // eslint-disable-next-line no-loop-func
            columns = columns.map((x, index) => {
                let addClass = x.className;
                if (rightJust.indexOf(x.key) >= 0) {
                    addClass += " rightJustified";
                }
                if(this.props.clickHandlers && this.props.clickHandlers[x.key]) {
                    addClass += " clickable";
                    return (<td onClick={()=>this.props.clickHandlers[x.key](x)} className={addClass.length > 0 ? addClass:null} key={ind + ";" + index}>{x.val}</td>);
                }else{
                    return (<td className={addClass.length > 0 ? addClass:null} key={ind + ";" + index}>{x.val}</td>);
                }
            });
            rows.push(<tr className={className.length > 0 ? className : null} key={ind++}>{columns}</tr>);
        }

        return (<tbody >{rows}</tbody >);
    }
    render() {
        return (
            <div className=" centered center-table">
                <h3 className="center-align">{this.props.title}</h3>
                {this.props.subHeader ? this.props.subHeader: null}
                <Table size="sm" responsive="sm" striped>
                    {this.getHeader()}
                    {this.getRows()}
                </Table>
            </div>
        );
    }
}

//SumTable
//Displays a Datatable, plus a "Total" row and column that sums all together
//Props: sumColumnName, sumRowName, sumColumns, nameColumn, {...datatable props}
function SumTable(props) {

    let c = function () {
        let cName = props.sumColumnName || "Total";
        props.keys.push(cName);
        let sumColumns = props.sumColumns || props.keys;
        for (let entry of props.items) {
            let s = 0;
            for (let key in entry) {
                if (sumColumns.indexOf(key) >= 0 && key !== props.nameColumn) {
                    s += entry[key];
                }
            }
            entry[cName] = s;
        }
        if (props.sumColumns) {
            props.sumColumns.push(cName);
        }
    };
    let r = function () {
        let rName = props.sumRowName || "Total";
        let totalData = {};
        let sumColumns = props.sumColumns || props.keys;
        for (let key of props.keys) {
            if (props.sumColumns.indexOf(key) >= 0) {
                totalData[key] = 0;
            } else {
                totalData[key] = "";
            }
        }
        totalData[props.nameColumn] = rName;

        for (let entry of props.items) {
            for (let key in entry) {
                if (sumColumns.indexOf(key) >= 0 && key !== props.nameColumn) {
                    totalData[key] += parseFloat(entry[key]);
                }
            }
        }
        props.items.push(totalData);
    };
    switch (props.sumDirection || "both") {
        case "rows": {
            r();
            break;
        }
        case "columns": {
            c();
            break;
        }
        case "both": {
            c();
            r();
            break;
        }
        default: {
            break;
        }
    }
    return <DataTable {...props}></DataTable>;
}

//Contains data. 
function DataTableContainer(props) {
    return <div className={props.size + " data-table-container"}>
        {props.children}
    </div>;
}



export { DatePick, DataTable,SumTable, Site, Routecontroller, GameOverviewTable, GameCategoryTable, SolitaireTable, BadWordTable, BadWordForm, CurrencySelector, RevenueTable, TopRevenueTable, DataTableContainer, MultiplayerStatTable, ServerTable, TrafficTable };

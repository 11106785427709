import React from 'react';
import { AgeService } from './../../../lib/util'; 
import { SumTable } from './../../components';

const ServerSumTable = ({ data, getStatus, getStatusColor }) => {
    const keys = ["Server", "Start Time", "Runtime", "Connected", "Max", "Tables", "Status"];
    const rightJustify = ["Connected", "Max", "Tables", "Status"];
    const sumColumns = ["Connected", "Max", "Tables"];
    const tableData = [];

    if (data.servers) {
        data.servers.forEach((server) => {
            const serverData = {
                "Server": server.name,
                "Start Time": server.responding ? new Date(server.startTime).toLocaleString() : "UNKNOWN",
                "Runtime": server.responding ? AgeService.getAgeString(AgeService.getAge(server.startTime), true) : "UNKNOWN",
                "Connected": server.responding ? server.connectedCount.current || 0 : 0,
                "Max": server.responding ? server.connectedCount.max || 0 : 0,
                "Tables": server.responding ? server.tables || 0 : 0,
                "Status": getStatus(server),
                "className": getStatusColor(getStatus(server))
            };
            tableData.push(serverData);
        });
    }

    return (
        <SumTable
            defaultValue=""
            rightJustify={rightJustify}
            sumColumns={sumColumns}
            sumDirection="rows"
            keys={keys}
            items={tableData}
            sumRowName="Total"
            nameColumn="Server"
        />
    );
};

export default ServerSumTable;

import React,{useState, useEffect} from 'react';
import ErrorPaginatedTable from '../Components/ErrorPaginatedTable';
import Container from '@mui/material/Container';
import ErrorSummary from '../Components/ErrorSummary';
import Typography from '@mui/material/Typography';
const ErrorPage = () => {
    const [summary, setSummary] = useState({});
    const [summaryDate, setSummaryDate] = useState(24);
    const [filterNames, setFilterNames] = useState([]);
    const [filterType, setFilterType] = useState('include');
    useEffect(() => {
        fetch('/api/errors/summary?'+ new URLSearchParams({
            interval: summaryDate
        })  , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(response => response.json()).then(data => {
            setSummary(data);
        }).catch(error => {
            console.error('Error fetching summary:', error);
        });
    }, [summaryDate]);

    const toggleFilterName = (name) => {
        if (filterNames.includes(name)) {
            setFilterNames(filterNames.filter(n => n !== name));
        } else {
            setFilterNames([...filterNames, name]);
        }
    };

    return (
        <Container maxWidth="xl" sx={{marginBottom:"20px"}}>
            <ErrorSummary errors={summary.rows} total={summary.total} summaryDate={summaryDate} filterType={filterType} setFilterType={setFilterType} setSummaryDate={setSummaryDate} setFilterNames={toggleFilterName} filterNames={filterNames}></ErrorSummary>
            <Typography variant="h4" textAlign="center" margin="30px 0px 1px 0px">Errors</Typography>
            <ErrorPaginatedTable filterNames={filterNames} setFilterNames={toggleFilterName} filterType={filterType}></ErrorPaginatedTable>
        </Container>
    );
};

export {ErrorPage};
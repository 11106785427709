import ServerSimpleGraph from "./ServerSimpleGraph";

const {useEffect, useRef} = require("react");
const MAX_HISTORY = 50;
const updateTable = (server,ref) =>{
    createAndLimit(ref,server.name);
    ref.current[server.name].push(server.connectedCount.current);
}
const updateMemory = (server,ref) =>{
    createAndLimit(ref,server.name);
    ref.current[server.name].push(server.memmoryUsage.process);
};
const updateCpu = (server,ref) =>{
    for (const element of server.cpuUsage){
    createAndLimit(ref,server.name+'-'+element.core);
    ref.current[server.name+'-'+element.core].push(element.usage);
    }
};
const createAndLimit = (ref,key) =>{
    // Ensure the server entry exists in the history
    if (!ref?.current[key]) {
        ref.current[key] = [];
    }
    // Push the current value to the history
    if(ref.current[key].length > MAX_HISTORY){
        ref.current[key].shift();
    }
}
const ServerGraphs = ({data,autoRefresh, setAutoRefresh})=>{

    // Use useRef to store the history
    const tablesRef = useRef({});
    const memoryRef = useRef({});
    const cpuRef = useRef({});
    
    
    useEffect(() => {
        if (data?.servers) {
            data.servers.forEach((server) => {
                updateTable(server,tablesRef);
                updateMemory(server,memoryRef);
                updateCpu(server,cpuRef);
            });
        }
    }, [data]);

    // You can access the history using tablesRef.current
    const tables = tablesRef.current;
    const memory = memoryRef.current;
    const cpu = cpuRef.current;

    return (
        <div>
            <div class="text-center">
            <h2>Server Graphs</h2>
            <p>Server Graphs will be displayed here</p>
            <button onClick={()=>setAutoRefresh(!autoRefresh)}>Turn {autoRefresh?'off':'on'} AutoRefresh</button>
            </div>
            <ServerSimpleGraph title="Connected" graphData={tables} />
            <ServerSimpleGraph title="Memory" graphData={memory} />
            <ServerSimpleGraph title="CPU" graphData={cpu} />
        </div>
    );
}

export default ServerGraphs;
import React, { useEffect, useState } from "react";
import { CompTable } from "./CompTable";
import { Sumtable } from "./SumTable";
import './style.css';
import {addDays } from 'date-fns';
import {  DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';


const freestarGames = [
    'hearts',
    'solitaire',
    'yahtzee',
    'freecell',
    'spidersolitaire',
    'euchre',
    'sudoku'
];

const googleGames = [
    'spades',
    'cribbage',
    'ginrummy',
    'rummy',
    'backgammon',
    'checkers',
    'chess',
    'pinochle',
    'golfsolitaire',
    'pyramidsolitaire',
    'tripeakssolitaire',
    'mahjong',
    'thirteen',
    'whist',
    'scorpionsolitaire',
    'crescentsolitaire',
    'ohhell',
    'crazyeights',
    'yukonsolitaire',
    'farkle',
    'canfieldsolitaire',
    'addiction',
    'reversi',
    'minesweeper',
    'triangles',
    'gofish',
    'spit',
    'kingsinthecorners',
    'avatars',
    'idiot',
    'clocksolitaire',
    'seabattle',
    'manni',
    'lockup',
    'switch',
    'war',
    'shithead',
];
const processData = (data) => {
    const validData = data.filter(d => !d.dimensionValues[0].value.match(/statistics|other|index.html|(not set)|how-to-play-|^\/$/));
    const freestar = validData.filter(d => d.dimensionValues[0].value.match(new RegExp('^/(' + freestarGames.join("|")+")", 'i')));
    const google = validData.filter(d => d.dimensionValues[0].value.match(new RegExp('^/('+ googleGames.join("|")+")", 'i')));

    return {
        freestar,
        google
    };
}

const lastMonth = () => {
    const d = new Date();
    const y = d.getMonth() === 1 ? d.getFullYear() - 1 : d.getFullYear();
    let m = (d.getMonth() + 11) % 12;
    m = m === 0 ? 12 : m;
    
    console.log(new Date(y, m, 1));
    console.log(new Date(y, m + 1, 0));
    const range = {
        from: new Date(y, m, 1),
        to: new Date(y,m + 1,0)
    }

    return range;
}

const datesInPast = (days) => {
    const today = new Date();
    const yesterday = addDays(today, -1);
    yesterday.setDate(today.getDate() - 1);
    const beforeThat = addDays(yesterday, -days);

    return {
        from: beforeThat,
        to: yesterday
    };

}

const getPreviousPeriod = (start, end) => {
    if(!start && !end){
        start = new Date();
    }
    start = start || end;
    end = end || start;


    const previousEndDate = new Date(start);
    previousEndDate.setDate(previousEndDate.getDate() - 1);
    const previousStartDate = new Date(previousEndDate);

    const delta = new Date(end).getTime() - new Date(start).getTime();
    const DayDifference = Math.floor(delta / (1000 * 3600 * 24));

    previousStartDate.setDate(previousEndDate.getDate() - DayDifference);

    return {
        from : previousStartDate,
        to : previousEndDate
    }
}

export const Freestar = () => {
    const today = new Date();
    const yesterday = addDays(today, -1);

    const defaultSelected = {
        from: yesterday,
        to: yesterday
      };
      
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({google :[], freestar:[]});
    const [oldData, setOldData] = useState({google :[], freestar:[]});
    const [dateRange, setDateRange] = useState(defaultSelected);
    const [error, setError] = useState();

    const updateData = () => {
        if(typeof(dateRange) === "undefined"){
            setError("Invalid Date.");
            return;
        }
        
        let to, from;
        to = dateRange.to || dateRange.from;
        from = dateRange.from || dateRange.to;
        if(from.getTime() > to.getTime()){
            setError("Start cannot be later than end.");
        } else if(to.getTime() > new Date().getTime()){
            setError("End cannot be in the future");
        } else {
            setError("");
            let wait = 2;
            const start = from.toISOString().split("T")[0];
            const end = to.toISOString().split("T")[0];
            setLoading(true);
            fetch(`api/ga4/pageViews?start=${start}&end=${end}`).then(resp => {
                setLoading(--wait > 0);
                setError("");
                if(resp.status === 200){
                    resp.json().then(data => setData(processData(data)));
                }
            });
            
            const prevRange = getPreviousPeriod(start, end);
    
            fetch(`api/ga4/pageViews?start=${prevRange.from.toISOString().split("T")[0]}&end=${prevRange.to.toISOString().split("T")[0]}`).then(resp => {
                setLoading(--wait > 0);
                setError("");
                if(resp.status === 200){
                    resp.json().then(data => setOldData(processData(data)));
                }
            });
        }
    }

    useEffect(updateData, [dateRange]);
    const oldPeriod = dateRange ? getPreviousPeriod(dateRange.from, dateRange.to) : null;

    return <div>
        <div className="title">
            <h1>Freestar/Google Comparison</h1>
            <i>(According to Google Analytics)</i>
        </div>
        <div>
        <DayPicker
            id="time_range_picker"
            mode="range"
            defaultMonth={yesterday}
            selected={dateRange}
            footer={error} disabled={loading}
            onSelect={range => {
                setDateRange(range, updateData);
            }}
        />
        </div>
        <div style={{"display":"flex", "justifyContent":"center"}}>
            <button disabled={loading} onClick={() => setDateRange(datesInPast(6), updateData)}>Last 7 days</button>
            <button disabled={loading} onClick={() => setDateRange(datesInPast(30), updateData)} >Last 30 days</button>
            <button disabled={loading} onClick={() => setDateRange(lastMonth(),updateData) } >Last Month</button>
            <button disabled={loading} onClick={() => setDateRange(datesInPast(364 / 2), updateData)} >Last 6 months</button>
        </div>
        <div style={{"text-align":"center"}}>
            {dateRange ? <b>Displaying the period between {(dateRange.from || dateRange.to).toDateString()} and {(dateRange.to || dateRange.from).toDateString()}</b> : <b>Invalid Date</b>}<br></br>
            {dateRange ? <small>Previous period: {oldPeriod.from.toDateString()} and {oldPeriod.to.toDateString()}</small> : null}<br></br>
        </div>
        <div>
            {
                loading ? <b>Loading</b> : <div>
                    <div className="cards">
                        <Sumtable  oldData={oldData} data={data}/> 
                    </div>
                    <div className="tables">
                        <CompTable data={data} oldData={oldData}/>
                    </div>
            </div>
            }
            
        </div>
    </div>;
}; 
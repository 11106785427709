import React from "react";
import {Card} from 'react-bootstrap';


const METRICENUM = {
    ACTIVEUSERS : 0,
    AVERAGESSESSIONDURATION : 1,
    BOUNCERATE : 2,
    NEWUSERS : 3,
    SESSIONS : 4
}

const getSetData = (set) => {
    let activeUsers = 0;
    let averageSessionDuration = 0;
    let sessions = 0;
    let newUsers = 0;
    let bouncerate = 0;
    let totalTime = 0;
    
    for(const game of set){
        const metrics = game.metricValues;
        activeUsers += parseInt(metrics[METRICENUM.ACTIVEUSERS].value);
        sessions += parseInt(metrics[METRICENUM.SESSIONS].value);
        bouncerate += parseInt(metrics[METRICENUM.BOUNCERATE].value);
        averageSessionDuration += parseFloat(metrics[METRICENUM.AVERAGESSESSIONDURATION].value);
        newUsers += parseInt(metrics[METRICENUM.NEWUSERS].value);
        totalTime += parseInt(metrics[METRICENUM.SESSIONS].value) * parseFloat(metrics[METRICENUM.AVERAGESSESSIONDURATION].value);
    }
    return {
        activeUsers : {
            sum : activeUsers,
            average : activeUsers / set.length
        }, 
        averageSessionDuration : {
            sum : averageSessionDuration,
            average : averageSessionDuration / set.length
        }, 
        sessions : {
            sum : sessions,
            average : sessions / set.length
        }, 
        newUsers : {
            sum : newUsers,
            average : newUsers / set.length
        }, 
        bouncerate : {
            sum : bouncerate,
            average : bouncerate / set.length
        }, 
        totalTime : {
            sum : totalTime,
            average : totalTime / set.length
        }
    }
}

export const secondsToTimeString = (totalSeconds, addSpace = false) => {
    let remainingSeconds = totalSeconds;
    const secondsInYears = 31536000;
    const secondsInMonths = secondsInYears / 12;
    const secondsInDay = 86400;
    const secondsInHour = 3600;
    const secondsInMinute = 60;
    if(totalSeconds >= secondsInYears){
        return `${(totalSeconds / secondsInYears).toFixed(2)} Years`;
    } else if(totalSeconds >= secondsInMonths){
        return `${(totalSeconds / secondsInMonths).toFixed(2)} Months`;
    } else if(totalSeconds >= secondsInDay){
        return `${(totalSeconds / secondsInDay).toFixed(2)} Days`;
    }
    let hours, minutes, seconds;
    seconds = Math.floor(remainingSeconds % 60);
    remainingSeconds = remainingSeconds % secondsInDay;
    hours = Math.floor(remainingSeconds / secondsInHour);
    remainingSeconds = remainingSeconds % secondsInHour;
    minutes = Math.floor(remainingSeconds / secondsInMinute);
    
    return `${totalSeconds >= secondsInHour ? `${hours}:`:''}${minutes}:${seconds}`;
}

const ComparisonCard = ({title, freestar, google, stringTransform = (e) => e}) => <Card className="card-style">
    <Card.Body>
        <Card.Text className="card-title">{title}</Card.Text>
        <Card.Text className="big-number">{stringTransform(freestar)} vs {stringTransform(google)}</Card.Text>
        <Card.Text style={{"position":"absolute", "bottom":"1em"}} className={freestar < google ? 'color-red' : 'color-green'}>
            {freestar > google ? "Freestar wins!" : "Google Wins!"} ({(100 * (freestar / (freestar + google))).toFixed(2)}% of total)
        </Card.Text>
    </Card.Body>
</Card>

const SincePreviousCard = ({title, subtitle,  now, before, stringTransform = (e) => e}) => <Card className="card-style">
    <Card.Body>
        <Card.Text className="card-title">{title} <i className="card-italic">{subtitle}</i></Card.Text>
        <Card.Text className="big-number">{stringTransform(now)} vs {stringTransform(before)}</Card.Text>
        <Card.Text style={{"position":"absolute", "bottom":"1em"}} className={now < before ? 'color-red' : 'color-green'}>
            {(100 * ((now-before) / before)).toFixed(2)}% {now > before ? 'increase' : 'decrease'}
        </Card.Text>
    </Card.Body>
</Card>

export const Sumtable = ({data, oldData}) => {
    const freestarData = getSetData(data.freestar);
    const googleData = getSetData(data.google);

    const oldFreestarData = getSetData(oldData.freestar);
    const oldGoogleData = getSetData(oldData.google);
    return <div>
        <h2>Comparison</h2>
        <div style={{'display':'flex', 'justify-content':'center'} }>
            <ComparisonCard title="Total Sessions" freestar={freestarData.sessions.sum} google={googleData.sessions.sum}/>
            <ComparisonCard title="Average Session Duration" stringTransform={secondsToTimeString}  freestar={(freestarData.averageSessionDuration.average)} google={(googleData.averageSessionDuration.average)}/>
            <ComparisonCard title="Total Time Spent across all games" stringTransform={secondsToTimeString}  freestar={(freestarData.totalTime.sum)} google={(googleData.totalTime.sum)}/>
            <ComparisonCard title="Average total Time spent per game" stringTransform={secondsToTimeString} freestar={(freestarData.totalTime.average)} google={(googleData.totalTime.average)}/>
        </div>
        <h2>Freestar Games Summary</h2>
        <div style={{'display':'flex', 'justify-content':'center'} }>
            <SincePreviousCard title="Total Sessions" subtitle="vs previous period" now={freestarData.sessions.sum} before={oldFreestarData.sessions.sum}/>
            <SincePreviousCard title="Average Session Duration" subtitle="vs previous period" stringTransform={secondsToTimeString}  now={(freestarData.averageSessionDuration.average)} before={(oldFreestarData.averageSessionDuration.average)}/>
            <SincePreviousCard title="Total Time Spent" subtitle="vs previous period" stringTransform={secondsToTimeString}  now={(freestarData.totalTime.sum)} before={(oldFreestarData.totalTime.sum)}/>
            <SincePreviousCard title="Average Time Spent per game"  subtitle="vs previous period" stringTransform={secondsToTimeString} now={(freestarData.totalTime.average)} before={(oldFreestarData.totalTime.average)}/>
        </div>        
        
        <h2>Google Games Summary</h2>
        <div style={{'display':'flex', 'justify-content':'center'} }>
            <SincePreviousCard title="Total Sessions" subtitle="vs previous period" now={googleData.sessions.sum} before={oldGoogleData.sessions.sum}/>
            <SincePreviousCard title="Average Session Duration" subtitle="vs previous period" stringTransform={secondsToTimeString}  now={(googleData.averageSessionDuration.average)} before={(oldGoogleData.averageSessionDuration.average)}/>
            <SincePreviousCard title="Total Time Spent" subtitle="vs previous period" stringTransform={secondsToTimeString}  now={(googleData.totalTime.sum)} before={(oldGoogleData.totalTime.sum)}/>
            <SincePreviousCard title="Average Time Spent per game"  subtitle="vs previous period" stringTransform={secondsToTimeString} now={(googleData.totalTime.average)} before={(oldGoogleData.totalTime.average)}/>
        </div>
    </div>
}
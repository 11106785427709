import { LineChart } from '@mui/x-charts/LineChart';
const ServerSimpleGraph = ({ graphData, title}) =>{
    const series = [];
    for (const propery in graphData) {
        series.push({
            id: propery,
            data: graphData[propery],
            label: propery,
        });
    }
    return (
        <div style={{ padding: "15px",background:"#fafafa" }}>
            <h3 style={{textAlign:"center"}}>{title}</h3>
            <LineChart
                    series={series}
                    height={300}
            />
        </div>
    )
}
export default ServerSimpleGraph;
import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import ServerTable  from './../Components/Server/ServerTable';

const Servers = () => {
    const [pending, setPending] = useState(false);
    const [data, setData] = useState({});

    const refreshData = async () => {
        setPending(true);
        try {
            const response = await fetch('/api/servers');
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching server data:', error);
        } finally {
            setPending(false);
        }
    };

    useEffect(() => {
        refreshData();
    }, []);

    const pendingAlert = pending ? (
        <Alert variant="warning">
            Fetching data. Please wait, this can take several seconds as we poll all servers
        </Alert>
    ) : null;

    return (
        <div>
            {pendingAlert}
            <ServerTable pending={pending} onFetch={refreshData} data={data} />
        </div>
    );
};

export default Servers;
